<template>
  <div>
    <div class="iq-top-navbar">
            <div class="iq-navbar-custom">
               <div class="iq-sidebar-logo">
                  <div class="top-logo">
                     <a href="index.html" class="logo">
                     <div class="iq-light-logo">
                  <img src="images/logo.gif" class="img-fluid" alt="">
               </div>
               <div class="iq-dark-logo">
                  <img src="images/logo-dark.gif" class="img-fluid" alt="">
               </div>
                     <span>vito</span>
                     </a>
                  </div>
               </div>
               <nav class="navbar navbar-expand-lg navbar-light p-0">
                  <div class="navbar-left">
                   
                  <div class="iq-search-bar d-none d-md-block">
                     <div   class="searchbox">
                        <h5><i :class="AppName.icon"></i>&nbsp;{{AppName.name}}</h5>
                        <nav aria-label="breadcrumb">
                              <ol class="breadcrumb"> 
                                 <li class="breadcrumb-item"
                                  v-for="breadcrumb in getBreadcrumb" v-bind:key="breadcrumb.menu">
                                  <router-link :to = "breadcrumb.link">
                                    {{breadcrumb.menu}}
                                  </router-link> </li> 
                              </ol>
                           </nav>
                          
                     </div>
                  </div>
               </div>
               <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-label="Toggle navigation">
               <i class="ri-menu-3-line"></i>
               </button>
               <div class="iq-menu-bt align-self-center">
                  <div class="wrapper-menu">
                     <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
                     <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
                  </div>
               </div>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                     <ul class="navbar-nav ml-auto navbar-list"> 
                        
                        <li class="nav-item">
                           <!-- <a href="#" class="search-toggle iq-waves-effect"> 
                              <i class="fa fa-bell"></i>
                              <span class="bg-danger dots"></span>
                           </a>
                           <div class="iq-sub-dropdown">
                              <div class="iq-card shadow-none m-0">
                                 <div class="iq-card-body p-0 ">
                                    <div class="bg-primary p-3">
                                       <h5 class="mb-0 text-white">All Notifications
                                          <small class="badge  badge-light float-right pt-1">{{information.length }}</small></h5>
                                    </div>
                                    <div v-for="information in information" v-bind:key ="information.key">
                                       <router-link to ="" class="iq-sub-card" >
                                       <div class="media align-items-center">
                                          <div class="">
                                             <img class="avatar-40 rounded" :src="information.img" alt="">
                                          </div>
                                          <div class="media-body ml-3">
                                             <h6 class="mb-0 ">{{information.name}}</h6>
                                             <small class="float-right font-size-12">{{information.date}}</small>
                                             <h6 class="mb-0">{{information.header}}</h6>
                                             <p class="mb-0">{{information.TimeRanges}}</p>
                                          </div>
                                       </div>
                                    </router-link>
                                    </div> 
                                 </div>
                              </div>
                           </div> -->
                           
                        </li>
                        <li class="nav-item dropdown">
                           <!-- <a href="#" class="search-toggle iq-waves-effect">
                            <i class="fa fa-envelope"></i>
                              <span class="bg-primary count-mail"></span>
                           </a>
                           <div class="iq-sub-dropdown">
                              <div class="iq-card shadow-none m-0">
                                 <div class="iq-card-body p-0 ">
                                    <div class="bg-primary p-3">
                                       <h5 class="mb-0 text-white">All Messages
                                          <small class="badge  badge-light float-right pt-1">{{messages.length}}</small></h5>
                                    </div>
                                    <div v-for="messages in messages" v-bind:key='messages.key'>
                                       <a href="#" class="iq-sub-card" >
                                       <div class="media align-items-center">
                                          <div class="">
                                             <img class="avatar-40 rounded" :src="messages.img" alt="">
                                          </div>
                                          <div class="media-body ml-3">
                                             <h6 class="mb-0 ">{{messages.name}}</h6>
                                             <small class="float-left font-size-12">{{messages.date}}</small>
                                             <br>
                                             <p><b>{{messages.header}}</b><br>{{messages.type}}</p>
                                          </div>
                                       </div>
                                    </a>
                                    </div> 
                                 </div>
                              </div>
                           </div> -->
                        </li>
                     </ul>
                  </div>
                  <ul class="navbar-list">
                      <li> 
                        <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded">
                           <img src="images/asb/asb.jpg" class="img-fluid rounded mr-3" alt="user">
                           <div class="caption">
                              <h6 class="mb-0 line-height text-white">{{userData.username}}</h6> 
                           </div>
                        </a>
                        <div class="iq-sub-dropdown iq-user-dropdown">
                           <div class="iq-card shadow-none m-0">
                              <div class="iq-card-body p-0 ">
                                 <div class="bg-primary p-3">
                                    <h5 class="mb-0 text-white line-height">Hello </h5>
                                    <span class="text-white font-size-12">{{userData.nm_lengkap}}</span>
                                 </div>
                                 <router-link to="/profile" class="iq-sub-card iq-bg-primary-hover">
                                    <div class="media align-items-center">
                                       <div class="rounded iq-card-icon iq-bg-primary">
                                          <i class="ri-file-user-line"></i>
                                       </div>
                                       <div class="media-body ml-3">
                                          <h6 class="mb-0 ">My Profile</h6>
                                          <p class="mb-0 font-size-12">View personal profile details.</p>
                                       </div>
                                    </div>
                                 </router-link>
                                  
                                 <!-- <a href="account-setting.html" class="iq-sub-card iq-bg-primary-hover">
                                    <div class="media align-items-center">
                                       <div class="rounded iq-card-icon iq-bg-primary">
                                          <i class="ri-account-box-line"></i>
                                       </div>
                                       <div class="media-body ml-3">
                                          <h6 class="mb-0 ">Account settings</h6>
                                          <p class="mb-0 font-size-12">Manage your account parameters.</p>
                                       </div>
                                    </div>
                                 </a>
                                 <a href="privacy-setting.html" class="iq-sub-card iq-bg-primary-hover">
                                    <div class="media align-items-center">
                                       <div class="rounded iq-card-icon iq-bg-primary">
                                          <i class="ri-lock-line"></i>
                                       </div>
                                       <div class="media-body ml-3">
                                          <h6 class="mb-0 ">Privacy Settings</h6>
                                          <p class="mb-0 font-size-12">Control your privacy parameters.</p>
                                       </div>
                                    </div>
                                 </a> -->
                                 <div class="d-inline-block w-100 text-center p-3">
                                    <router-link  class="btn btn-primary dark-btn-primary"  to="/login" role="button">Sign out<i class="ri-login-box-line ml-2"></i></router-link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </li>
                  </ul>
               </nav> 
            </div>
         </div>
  </div>
</template>

<script>
   import axios from '../../config/Axios'
import {   mapState, mapMutations  } from 'vuex'
    
  export default {
    data: () => ({
       jumlah_pesan :""
    }),
    mounted() {
       this.Profil()

    },
    computed: {
      ...mapState({
         getBreadcrumb: state => state.Breadcrumb.getBreadcrumb,
         AppName : state => state.Breadcrumb.AppName,
         information : state => state.Information.information,
         messages : state => state.Messages.messages,
         userData : state => state.Users.userData,
            }),
    },
    methods: { 
      ...mapMutations({
                SetUser: 'SetUser',
            }),
            async Profil() {
               const isAuthenticated = localStorage.getItem('token_auth');               
                const self = this;
                await axios({
                        method: 'PUT',
                        url: 'api/aut/profil',
                        data: self.loginData,
                        headers: {
                            'Authorization': isAuthenticated 
                        }
                    })
                    .then(function (response) {
                        self.resultUser = response.data;
                        self.SetUser(response.data.result); 
                    }).catch(err => {
                        console.log(err);
                    });

            },
    }

  }
</script>

<style>

</style>