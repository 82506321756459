<template>
    <div class="wrapper">
                <div class="container-fluid p-0">
                    <div class="row no-gutters">
                        <div class="col-sm-12 text-center">
                            <div class="iq-error">
                                <img src="images/error/login_lagi.png" class="img-fluid iq-error-img" alt="" style="width :400px">
                                <h2 class="mb-0">Oops! User terdeteksi login di perangkat lain....</h2>
                                <p>Jika anda merasa tidak melakukanya, silahkan login kembali dan kami sarankan untuk mengganti password anda.. <br>
                                     <router-link  to="/login">
                                     <button class="btn btn-primar" >
                                        <i class="fa fa-sign-in"></i> <b>Back to login</b>
                                         </button></router-link> 
                                     </p>                         
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </template> 
    <script>
    export default {
    
    }
    </script>
    
    <style>
    
    </style>