<template>
    <div>
        <vs-dialog v-model="active" width='500px' prevent-close not-close>
            <div class="iq-card-body">
                <div class="form-group">
                    <label for="email">Nama Menu: </label>
                    <input type="text" class="form-control" id="email1" name="nm_menu" v-model="subMenu.nm_menu"
                        v-validate="'required|min:3'" placeholder="nama menu">
                    <span class="text-danger">{{ errors.first('nm_menu') }}</span>
                </div>
                <div class="form-group">
                    <label for="email">Url:</label>
                    <input type="text" class="form-control" id="email1" name="url" v-validate="'required|min:3'"
                        v-model="subMenu.url">
                    <span class="text-danger">{{ errors.first('url') }}</span>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="email">Order:</label>
                            <input type="number" class="form-control" id="email1" name="order" v-validate="'required'"
                                v-model="subMenu.order">
                        </div>
                    </div>
                    <span class="text-danger">{{ errors.first('order') }}</span>
                </div>
                <div class="form-group">
                    <label for="email">Status</label>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio1" name="customRadio" value="1" v-model="subMenu.st"
                                    class="custom-control-input">
                                <label class="custom-control-label" for="customRadio1"> Enable</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio2" name="customRadio" value="0" v-model="subMenu.st"
                                    class="custom-control-input">
                                <label class="custom-control-label" for="customRadio2"> Disable</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-primary btn-sm mr-1" @click="act_save()" v-if="act_ == 'c'">
                    <i class="fa fa-save"></i>Submit
                </button>
                <button class="btn btn-primary btn-sm mr-1" @click="act_update()" v-else>
                    <i class="fa fa-save"></i>Update
                </button>
                <button class="btn iq-bg-danger btn-sm ml-1" @click="close()"> <i
                        class="fa fa-close"></i>cancle</button>

            </div>
        </vs-dialog>
    </div>
</template>

<script>
    import axios from '../../../../config/Axios';
    import Base from '../../../../mixins/Base';
    export default {
        emits: ["CloseSub", "suMenuParse"],
        mixins: [Base],
        data() {
            return {

            }
        },
        props: {
            active2: {},
            sub_menu: {},
            act: {}
        },
        computed: {
            active() {
                return this.active2;
            },
            Menu() {
                return this.sub_menu;
            },
            subMenu() {
                return this.sub_menu;
            },
            act_() {
                return this.act;
            }
        },
        mounted() {},
        methods: {
            close() {
                this.$emit("CloseSub", '');
            },
            act_save() { 
                const self = this;
                self.$validator.validateAll().then(result => {
                    if (result) {
                        axios({
                                method: 'POST',
                                url: 'api/acl/menu/add_sub',
                                data: self.subMenu,
                                headers: {
                                    'Authorization': self.isAuthenticated
                                }
                            })
                            .then(function (response) {
                                self.notification('', response.data.status, response.data.message);
                                console.log("respoen add", response.data.result);
                                self.$emit("suMenuParse", response.data.result);
                                self.$emit("CloseSub", '');

                            }).catch(err => {
                                self.pesan = err.message;
                                self.notification('warning', "Error", err.message);
                            });
                    }
                })
            },
            act_update() {
                const self = this;
                this.$validator.validateAll().then(result => {
                    if (result) {
                        axios({
                                method: 'POST',
                                url: 'api/acl/menu/update_sub',
                                data: self.menu,
                                headers: {
                                    'Authorization': self.isAuthenticated
                                }
                            })
                            .then(function (response) {
                                self.notification('', response.data.status, response.data.message);
                                self.$emit("CloseSub", '');

                            }).catch(err => {
                                self.pesan = err.message;
                                self.notification('warning', "Error", err.message);
                            });
                    }
                })
            },
        }
    }
</script>

<style>

</style>