<template>
    <div> 
        <!-- "kode_kegiatan": "1 01 02 2,01 01", "nama_kegiatan_sub": "Pembangunan Unit Sekolah Baru (USB)", "total_belanja": "48000", 
        "batas_atas_belanja": "38000", "selisih_asb": "10000", "prosen": "26.32" }, { "kode_kegiatan": "1 01 02 2,01 01", "nama_kegiatan_sub": 
        "Pembangunan Unit Sekolah Baru (USB)", "total_belanja": "100000", "batas_atas_belanja": "480", "selisih_asb": "99520", "prosen": "20733.33" } -->
        <div class="">
            <div class=" ">
                <div class="table-responsive">
                    <table class="table mb-0 table-borderless" width="100%">
                        <thead>
                            <tr>
                                <th width="30%">Nama Kegiatan </th>
                                <th width="15%">Batas Atas Menurut ASB </th>
                                <th width="15%">Belanja Menurut RKA</th> 
                                <th width="15%">Kelebihan Belanja</th> 
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="rs in daftar_tidak_wajar" v-bind:key="rs.kode_rumpun"> 
                                <td> {{rs.kode_kegiatan}} - {{rs.nama_kegiatan_sub}} </td>
                                <td> Rp. <span class="float-right mr-5">{{formatPrice(parseInt( rs.batas_atas_belanja))}} </span> </td>
                                <td> Rp. <span class="float-right">{{formatPrice(parseInt( rs.total_belanja))}} </span>   </td>  
                                <td > Rp. <span class="float-right text-danger">
                                    <b>{{formatPrice(parseInt( rs.total_belanja) - parseInt( rs.batas_atas_belanja))}}</b>  </span>  </td> 
                                 


                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            daftar_tidak_wajar: []
        },
        comments: {
            rumpunData() {
                return this.daftar_tidak_wajar;
            }
        },
        methods:{
            formatPrice(value_2) {
        let val = (value_2 / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
        }


    }
</script>

<style>

</style>