<template>
    <div>
        <section class="sign-in-page">
            <div class="container bg-white mt-5 p-0">
                <div class="row no-gutters">
                    <div class="col-sm-6 align-self-center">
                        <div class="sign-in-from">
                            <h1 class="mb-0 dark-signin">Sign in</h1>
                            <p>Enter your username and password to access admin panel. </p>
                            <div class="mt-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Userame </label>
                                    <input type="text" class="form-control mb-0" id="exampleInputEmail1"
                                        placeholder="Enter email" v-model="loginData.username" v-validate="'required'"
                                        name="email">
                                </div>
                                <span class="text-danger">{{ errors.first('email') }}</span>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Password</label>
                                    <input type="password" class="form-control mb-0" id="exampleInputPassword1"
                                        placeholder="Password" v-model="loginData.password" v-validate="'required'"
                                        name="password">
                                </div>
                                <span class="text-danger">{{ errors.first('password') }}</span>
                                <div class="d-inline-block w-100">
                                    <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                    </div>

                                    <button class="btn btn-primary float-right" @click="authLogin()"><i class="fa fa-sign-in"></i>Sign
                                        in</button>


                                </div>
                                <div class="sign-info">
                                    <span class="dark-color d-inline-block line-height-2">
                                        Copyright 2022 <a href="https://kjays.id">KJAYS</a> 
                                    </span>
                                    <!-- <ul class="iq-social-media">
                                        <li>
                                               <a href="https://www.freepik.com/free-vector/site-stats-concept-illustration_7140739.htm#query=analitic&position=3&from_view=search&track=sph">Image by storyset</a> on Freepik</li>
                                        <li><a href="#"><i class="ri-twitter-line"></i></a></li>
                                        <li><a href="#"><i class="ri-instagram-line"></i></a></li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 text-center">
                        <div class="sign-in-detail_1 text-white">
                            <!-- <a class="sign-in-logo mb-5" href="#"><img src="images/logo-white.png" class="img-fluid"
                                    alt="logo"></a> -->
                            <div class="slick-slider11" data-autoplay="true" data-loop="true" data-nav="false"
                                data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1"
                                data-items-mobile="1" data-items-mobile-sm="1" data-margin="0">
                                <div class="item mt-5">
                                    <img src="images/asb/asb.png" class="img-fluid mb-4" alt="logo">
                                    <h4 class="mb-1 text-white">Implementasi Analisis Standar Belanja</h4>
                                    <p>Pemerintah Kabupaten dan Kota</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {
        mapMutations
    } from 'vuex'
    import axios from '../config/Axios'
    import axios_d from 'axios'


    export default {
        data() {
            return {
                resultUser: {},
                loginData: {},
                ip: '',

            }
        },
        computed: {

        },
        mounted() {
            localStorage.setItem("authLogin", false);
            localStorage.setItem("token_auth", "");
            this.get_ip();
        },
        methods: {
            ...mapMutations({
                SetUser: 'SetUser',
            }),
            notification(title, text) {
                this.$vs.notification({
                    duration: '10000',
                    position: 'top-left',
                    title: '<b> ' + title + ' </b>',
                    text: text
                })

            },
            async Profil(isAuthenticated) {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: 'api/aut/profil', 
                        headers: {
                            'Authorization': isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.resultUser = response.data;
                        self.SetUser(response.data.result);
                    }).catch(err => {
                        console.log(err);
                    });

            },
            async get_ip() {
                const self = this;
                await axios_d({
                        method: 'GET',
                        url: 'https://api.ipify.org?format=json',
                        responseType: 'json',
                    })
                    .then(function (response) {
                        self.ip = response.data.ip
                    }).catch(err => {
                        console.log(err);
                    });

            },
            authLogin() {
                const self = this;
                this.$validator.validateAll().then(result => {
                    if (result) {
                        axios({
                                method: 'POST',
                                url: 'api/aut/login',
                                data: {
                                    username: self.loginData.username,
                                    password: self.loginData.password,
                                    ip: self.ip
                                }
                            })
                            .then(function (response) {
                                if (response.data.status == '201') {
                                    self.notification("Silahkan Login Kembali", response.data.message)
                                }
                                if (response.data.status == '200') {
                                    localStorage.setItem("authLogin", true);
                                    localStorage.setItem("token_auth", response.data.result);
                                    self.Profil(response.data.result);
                                    self.pesan = response.data.message;
                                    self.$router.push({
                                        name: 'Home'
                                    })
                                }
                            }).catch(err => {
                                self.notification("505", "Maaf.... Sambungan ke server terputus.");
                                console.log(err);
                            });
                    }
                });


                // if (self.loginData.username == null || self.loginData.password == null) {
                //     return self.$router.push({
                //         name: 'Login'
                //     })
                // }
                // await axios({
                //         method: 'POST',
                //         url: 'api/aut/login',
                //         data: self.loginData
                //     })
                //     .then(function (response) {
                //         self.resultUser = response.data;
                //         console.log(response.data)

                //     }).catch(err => {
                //         console.log(err);
                //     });

                //
            },

            login() {
                const self = this;
                self.SetUser({
                    img: "images/user/02.jpg",
                    name: "Dr. Wahyu Budi Santosa, S.Kom., M.Kom",
                    username: "wbsantosa",
                    email: "wahyubudisantosa@gmail.com",
                    level: "roots"
                });
                self.$router.push({
                    name: 'Home'
                })
            }
        }

    }
</script>

<style>
.sign-in-detail_1{
    background: rgb(8, 179, 185) !important;
}
</style>