export default {
    mamespaced : true,
    state: { 
        ProfilData: {}, 
    },
    mutations: { 
        SetProfil(state, value) {
            state.ProfilData = value 
        }
    },
    actions: {
   },
   
}
