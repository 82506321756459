<template>
    <div class="center">
        <vs-dialog not-close v-model="dialogModal" width='500px' prevent-close>
            <div class=" ">
                <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                        <h4 class="card-title"><i class="fa fa-list mr-1"></i>Add Menu </h4>
                    </div>
                </div>
                <div class="iq-card-body">
                    <!-- "icon": "fa fa-gears",
				"nm_menu": "ACL",
				"url": "acl",
				"menu_key": "f7dc5b58-09dd-11ea-ba39-1cb72c27dd68",
				"order": "1",
				"st": "1",
				"views": "1",
                 -->
                    <div class="form-group">
                        <label for="email">Nama Menu:</label>
                        <input type="text" class="form-control" id="email1" name="nm_menu" v-model="menu.nm_menu"
                            v-validate="'required|min:3'" placeholder="nama menu">
                            <span class="text-danger">{{ errors.first('nm_menu') }}</span>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email">Icon:</label>
                                <input type="text" class="form-control" id="email1" name="icon" v-model="menu.icon"
                                    v-validate="'required'">
                            </div>
                            <span class="text-danger">{{ errors.first('icon') }}</span>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email">Order:</label>
                                <input type="number" class="form-control" id="email1" name="order"
                                    v-validate="'required'" v-model="menu.order">
                            </div>
                        </div>
                        <span class="text-danger">{{ errors.first('order') }}</span>
                    </div>

                    <div class="form-group">
                        <label for="email">Url:</label>
                        <input type="text" class="form-control" id="email1" name="url" v-validate="'required|min:3'"
                            v-model="menu.url">
                            <span class="text-danger">{{ errors.first('url') }}</span>
                    </div>

                    <div class="form-group">
                        <label for="email">Status</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio1" name="customRadio"
                                    value="1" v-model="menu.st"
                                        class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio1"> Enable</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="customRadio2" name="customRadio" 
                                    value="0" v-model="menu.st"
                                        class="custom-control-input">
                                    <label class="custom-control-label" for="customRadio2" > Disable</label>
                                </div>
                            </div>
                        </div> 
                    </div>

                    <div class="form-group">
                        <label for="email">View</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="views1" name="views"
                                    value="1" v-model="menu.views"
                                        class="custom-control-input">
                                    <label class="custom-control-label" for="views1"> View</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="custom-control custom-radio">
                                    <input type="radio" id="views2" name="views"
                                    value="0" v-model="menu.views"
                                        class="custom-control-input">
                                    <label class="custom-control-label" for="views2"> No View</label>
                                </div>
                            </div>
                        </div> 
                    </div> 
                    <div v-if="btn.c">
                        <button  class="btn btn-primary btn-sm mr-1" @click="simpan()"><i
                                class="fa fa-save"></i>Submit</button>
                        <button class="btn iq-bg-danger btn-sm ml-1" @click="close()"> <i
                                class="fa fa-close"></i>cancle</button> 
                    </div> 
                    <div v-if="btn.u">
                        <button class="btn btn-primary btn-sm mr-1" @click="updateMenu(menu)"><i
                                class="fa fa-save"></i>Update</button>
                        <button class="btn iq-bg-danger btn-sm ml-1" @click="close()"> <i
                                class="fa fa-close"></i>cancle</button> 
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
    import axios from '../../../config/Axios'
    import Base from '../../../mixins/Base'
    export default {
        mixins: [Base],
        data() {
            return {
                 
            }
        },
        emits: ["CloseButton"],
        props: {
            button: {},
            data_menu : {}

        },
        computed: {
            btn() {
                return this.button;
            },
            menu(){
                return this.data_menu;
            },
            dialogModal() {
                const st = this.button;
                if (st.c == true || st.u == true) {
                    return true;
                } else {
                    return false;
                }
            }

        },
        mounted() {



        },
        methods: {
            simpan() { 
                 const self = this;
                this.$validator.validateAll().then(result => { 
                    if (result) {
                        axios({
                                method: 'POST',
                                url: 'api/acl/menu/add',
                                data: self.menu,
                                headers: {
                                    'Authorization': self.isAuthenticated
                                }
                            })
                            .then(function (response) { 
                               self.notification('', response.data.status, response.data.message);
                               self.$emit("CloseButton", '');

                            }).catch(err => {
                                self.pesan = err.message;
                                self.notification('warning', "Error", err.message);
                            });
                    }
                })
            },
            updateMenu(){                 
                const self = this;
                this.$validator.validateAll().then(result => { 
                    if (result) {
                        axios({
                                method: 'POST',
                                url: 'api/acl/menu/update',
                                data: self.menu,
                                headers: {
                                    'Authorization': self.isAuthenticated
                                }
                            })
                            .then(function (response) { 
                               self.notification('', response.data.status, response.data.message);
                               self.$emit("CloseButton", '');

                            }).catch(err => {
                                self.pesan = err.message;
                                self.notification('warning', "Error", err.message);
                            });
                    }
                })
            },
            close() {
                this.$emit("CloseButton", '');
            }
        }

    }
</script>

<style>

</style>
