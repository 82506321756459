export default {
    mamespaced : true,
    state: { 
        rs_level: [], 
        rs_user : [],
        rs_menu : [],

    },
    mutations: { 
        SetLevel(state, value) {
            state.rs_level = value 
        },
        SetRsUser(state, value) {
            state.rs_user = value 
        },
        SetRsMenu(state, value) {
            state.rs_menu = value 
        }
    },
    actions: {
   },
   
}
