import {
    mapMutations,
  } from 'vuex'

const Base = {
    
    data(){
        return{ 
        }
      },
      mounted() {
        
      },
      computed: {
  
      },
      methods: {
        ...mapMutations({
          SetBreadcrumb: 'SetBreadcrumb', 
          setAppName : 'setAppName',
          setInformation : 'setInformation',
          setMessages : 'setMessages'
              }),
  
        seter_Breadcrumb(Breadcrumb , AppName ){ 
          this.setAppName(AppName);
          this.SetBreadcrumb(Breadcrumb) 
        },
        cekNotifikasi(){
          //axios get notifikasi
          this.setInformation(
            [
              {
                key :'0xaie61',
                img :'images/user/01.jpg',
                name :'Wahyu Budi Santosa',
                header :'Selamat datang di apps',
                date :'2 January 2021',
                TimeRanges :'5 Hari yang lalu'
              },
              {
                key :'0xaiew5',
                img :'images/user/02.jpg',
                name :'Ahmad Farisa',
                header :'Selamat datang di apps',
                date :'2 January 2021',
                TimeRanges :'5 Hari  yang lalu'
              }
            ]
          );
          this.setMessages(
            [
              {
                key :'0xaeee65',
                img :'images/user/01.jpg',
                name :'Wahyu Budi Santosa',
                header :'Selamat datang di apps',
                date :'2 January 2021',
                TimeRanges :'5 Hari',
                type : "Urgent...!"
              },
              {
                key :'0xede65',
                img :'images/user/02.jpg',
                name :'Ahmad Farisa',
                header :'Selamat datang di apps',
                date :'2 January 2021',
                TimeRanges :'5 Hari',
                type : "Next Jobs"
              }
            ]
          );

        }


  
  
  
      }
}


export default Base;