import axios from 'axios'
const isAuthenticated = localStorage.getItem('token_auth');
//http://localhost/ci_vue/server/index.php/
export default axios.create({
  baseURL: 'https://kjays.id/app_server/index.php/',
    // baseURL :'https://asbkendal.com/app_server/index.php',
  headers: {
    'Authorization': isAuthenticated,
    // "Content-Type": "multipart/form-data"

  }
})