<template>
  <div class="col-lg-12">
    <div class="row">
      <div class="col-md-6 iq-todo-page">
        <form class="position-relative">
          <div class="form-group mb-0">
            <input type="text" class="form-control todo-search mt-2" id="exampleInputEmail002" placeholder="Search"
              v-model="search" @input="search_level(search)">
            <a class="search-link" href="#"><i class="ri-search-line"></i></a>
          </div>
        </form>
      </div>
      <div class="col-md-6">
        <button class="btn btn-outline-primary mb-3 mt-2  float-right" @click="CreateLevel()"><i
            class="fa fa-plus"></i>Add Level</button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3" v-for="rs_level in rs_level" v-bind:key='rs_level.id_level'>
        <div class="iq-card iq-card-block iq-card-stretch ">
          <div class="iq-card-body  ">
            <div class="d-flex align-items-center my-head">
              <div class="icon iq-icon-box rounded dark-icon-light iq-bg-primary mr-3">
                <i :class="'fa ' +  rs_level.icon"></i>
              </div>
              <div class="iq-details col-sm-8 p-0">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="title text-dark h5">{{rs_level.level_name}}</span>
                  <div class="text-primary ">
                    <div class="iq-card-header-toolbar d-flex align-items-center">
                      <div class="dropdown">
                        <span class="dropdown-toggle text-primary" id="dropdownMenuButton6" data-toggle="dropdown"
                          aria-expanded="false">
                          <i class="ri-more-2-fill"></i>
                        </span>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton6" style="">
                          <a class="dropdown-item" href="#/acl/level" @click="EditUser(rs_level)">
                            <i class="ri-pencil-fill mr-2"></i>
                            Edit</a>
                          <a class="dropdown-item" h href="#/acl/level" @click="DeleteLevel(rs_level)">
                            <i class="ri-delete-bin-6-fill mr-2"></i>
                            Delete</a>
                          <a class="dropdown-item" href="#/acl/level" @click="kelolaLevelAkses(rs_level)">
                            <i class="fa fa-gears mr-2"></i>Kelola</a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <span class="" v-if="rs_level.coun_user != null ">
                  <a @click="userView(rs_level.id_level, rs_level)">
                    <b class="h5">{{rs_level.coun_user}} <i class="ri-user-search-line"></i> </b>
                  </a>
                </span>
                <span class="" v-else> 0 Users</span>
              </div>
            </div>
            <hr>
            <div class="wrapper-scroll-y my-custom-scrollbar ">
              <div v-if="rs_level.menu == ''">
                <button class="btn btn-outline-secondary align-items-center" @click="kelolaLevelAkses(rs_level)"><i
                    class="fa fa-plus"></i> Kelola Menu Akses</button>
              </div>
              <div v-for="menu in rs_level.menu" v-bind:key="menu.nm_menu">
                <div>
                  <div class="row ">
                    <div class="col-sm-8">
                      <i :class="'fa ' + menu.icon + ' mr-1'"></i> <b class=""> {{menu.nm_menu}}</b>
                    </div>
                    <div class="col-sm-4">
                      <i class="ri-ball-pen-fill ml-1 text-info" v-show="menu.c =='1'" v-if="menu.sub_jm < 1"></i>
                      <i class="ri-refresh-line ml-1 text-warning" v-show="menu.c =='1'" v-if="menu.sub_jm <1"></i>
                      <i class="ri-delete-bin-5-line ml-1 text-danger" v-show="menu.c =='1'" v-if="menu.sub_jm < 1"></i>
                    </div>
                  </div>

                  <div v-for="sub in menu.sub" v-bind:key="sub.key" class="ml-4">
                    <div class="row">
                      <div class="col-sm-7">{{sub.nm_menu}} </div>
                      <div class="col-sm-3 ml-2">
                        <i class="ri-ball-pen-fill ml-1  text-info" v-show="sub.c =='1'"></i>
                        <i class="ri-refresh-line ml-1  text-warning" v-show="sub.c =='1'"></i>
                        <i class="ri-delete-bin-5-line ml-1  text-danger" v-show="sub.c =='1'"></i>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>



    <user :active_user='active' :levelName='level_name' @closeBtn='closeBtn'></user>
    <LevelEdit :active_user='active' :addLevel='addLevel' @closeBtn='closeBtn'></LevelEdit>
    <LevelAdd :active_user='active' :addLevel='addLevel' :levelName=level_name @closeBtn='closeBtn'></LevelAdd>
    <level-akses :menuList='menuList' :active_user='active' :levelName='level_name' @closeBtn='closeBtn'></level-akses>
  </div>
</template>

<script>
  import Breadcrumb from '../../../mixins/Breadcrumb'
  import Base from '../../../mixins/Base'
  import axios from '../../../config/Axios';
  import User from './User.vue'
  import LevelEdit from './LevelEdit.vue';
  import LevelAdd from './LevelAdd.vue';
  import LevelAkses from './LevelAkses.vue';
  import {
    mapMutations,
    mapState
  } from 'vuex'

  export default {
    components: {
      User,
      LevelEdit,
      LevelAdd,
      LevelAkses

    },
    mixins: [Breadcrumb, Base],

    data() {
      return {
        search: "",
        level_name: {},
        active: {
          user: false,
          editLevel: false,
          addLevel: false
        },
        addLevel: {},
        menuList: {}
      }
    },
    mounted() {
      this.Loading();
      this.cek_token();
      this.cek_akses();
      this.seter_Breadcrumb([{
          menu: "Home",
          link: "/home"
        },
        {
          menu: "Level App",
          link: "/acl/level"
        }
      ], {
        name: "Level",
        icon: "fa fa-list"
      })
      this.GetLevel()
    },
    computed: {
      ...mapState({
        rs_level: state => state.AclLevel.rs_level,
        rs_user: state => state.AclLevel.rs_user,
        rs_menu: state => state.AclLevel.rs_menu
      })

    },
    methods: {

      ...mapMutations({
        SetLevel: 'SetLevel',
        SetRsUser: 'SetRsUser',
        SetRsMenu: 'SetRsMenu'

      }),

      EditUser(levelName) {
        this.active = {
          user: false,
          editLevel: true,
          addLevel: false,
          levelAkses: false
        }
        this.addLevel = levelName
      },
      CreateLevel() {
        this.active = {
          user: false,
          editLevel: false,
          addLevel: true,
          levelAkses: false
        }
        console.log(this.active);
        this.level_name = {};
        this.addLevel = {}
      },
      kelolaLevelAkses(levelName) {
         this.SetRsMenu([]); 

        this.Loading();
        this.active = {
          user: false,
          editLevel: false,
          addLevel: false,
          levelAkses: true
        }
        this.level_name = levelName;
        const self = this;
        axios({
            method: 'PUT',
            url: 'api/acl/level_akses/menu',
            data: {
              id_level: levelName.id_level
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            console.log(response);
            self.SetRsMenu(response.data.result); 

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async userView(val, val2) {
        const self = this;
        self.SetRsUser("");
        this.Loading();
        this.level_name = val2;
        this.active = {
          user: true,
          editLevel: false,
          addLevel: false,
          levelAkses: false
        }

        await axios({
            method: 'PUT',
            url: 'api/acl/level_route/user_level',
            data: {
              id_level: val
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.SetRsUser(response.data.result);

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });

      },

      closeBtn(val) {
        this.active = val,
        this.GetLevel(this.search)
      },
      search_level() {
        this.GetLevel(this.search);
      },
      async DeleteLevel(rs_level) {
        if (confirm("Apakah anda akan menghapus data ini") == true) {
          const self = this;
          await axios({
              method: 'POST',
              url: 'api/acl/level/delete',
              data: rs_level,
              headers: {
                'Authorization': self.isAuthenticated
              }
            })
            .then(function (response) {
              self.Loading();
              self.SetLevel(response.data.result);
              self.notification('', response.data.status, response.data.message);

            }).catch(err => {

              self.pesan = err.message;
              self.notification('warning', "Error", err.message);
            });
        }
      },
      async GetLevel(search) {
        const self = this;
        await axios({
            method: 'PUT',
            url: 'api/acl/level/list',
            data: {
              cari: search
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.SetLevel(response.data.result);
            //self.rs_level = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }
  }
</script>

<style>
  .my-head {
    position: relative;
    height: 100px;
  }

  .my-custom-scrollbar {
    position: relative;
    height: 300px;
    overflow: auto;
  }

  .wrapper-scroll-y {
    display: block;
  }
</style>