<template>
  <div class=" ">
    <!-- <div class="row mr-1 ml-1">
      <div class="col-sm-12">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-body">
            <div class="iq-advance-course d-flex align-items-center justify-content-between">
              <div class="left-block">
                <div class="d-flex align-items-center">
                  <img src="images/page-img/29.png" class="img-fluid" alt="icon-img">
                  <div class=" ml-3">
                    <h4 class="">Download Panduan Cara Penggunaan Aplikasi Analisa Standar Belanja</h4>
                    <p class="mb-0">boost your professional career by adding new skills with our advanced courses.</p>
                  </div>
                </div>
              </div>
              <div class="right-block position-relativ">
                <a href="#" class="btn btn-primary"><i class="fa fa-download"></i>Download Now</a>
                <img src="images/page-img/34.png" class="img-fluid image-absulute image-absulute-1" alt="icon-img">
                <img src="images/page-img/35.png" class="img-fluid image-absulute image-absulute-2" alt="icon-img">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row mr-1 ml-1">
      <div class="col-lg-12">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-body scr">
            <div class="row mb-2">
              <div class="col-md-6 col-lg-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0">
                  <div class="rounded-circle iq-card-icon iq-bg-info  mr-3"> <i class="fa fa-tags"></i></div>
                  <div class="text-left"> 
                    <h4>{{jm_kegiatan.total}} </h4>
                    <p class="mb-0">Sub Kegiatan</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="d-flex align-items-center mb-3 mb-lg-0">
                  <div class="rounded-circle iq-card-icon iq-bg-info mr-3"> <i class="fa fa-check"></i></div>
                  <div class="text-left">
                    <h4>{{jm_kegiatan.wajar}} <small>Wajar</small></h4>
                    <p class="mb-0">Sub Kegiatan</p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-3">
                <div class="d-flex align-items-center mb-3 mb-md-0">
                  <div class="rounded-circle iq-card-icon iq-bg-danger  mr-3"> <i class="fa  fa-warning"></i></div>
                  <div class="text-left">
                    <h4>{{jm_kegiatan.tidak_wajar}} <small>Tidak Wajar</small></h4>
                    <p class="mb-0">Sub Kegiatan</p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-3">
                <div class="d-flex align-items-center mb-3 mb-md-0">
                  <div class="rounded-circle iq-card-icon iq-bg-primary  mr-3"> <i class="fa fa-sitemap "></i></div>
                  <div class="text-left">
                    <h4>{{jumlah_rumpun.jm_rumpun}} </h4>
                    <p class="mb-0">Rumpun Kegiatan</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 col-md-6">
        <div class="col-sm-12">
          <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div class="iq-card-body">
              <div class="row">
                <BarChart></BarChart>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="col-lg-4 col-md-6 pr-4 pl-4"> 
        <div class="row ">
          <div class="col-sm-12 col-md-6 col-lg-12 ">
            <div class="card iq-mb-3 text-white bg-info  ">
              <div class="iq-card-body  mb-2">
                <div class="text-right">
                  <div>
                    <h6 class="mb-0 text-white">Total Belanja Sub kegiatan</h6>
                    <span class="h3 text-white mb-0 counter d-inline-block w-100 mt-3">Rp. {{formatPrice(parseInt(jumlah_rumpun.total_belanja))}},- </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-12">
            <div class="card iq-mb-3 text-white bg-primary">
              <div class="iq-card-body">
                <div class="text-right"> 
                  <div>
                    <h6 class="mb-0  text-white">Batas Atas Belanja Sub kegiatan Menurut ASB </h6>
                    <span class="h3 text-white mb-0 counter d-inline-block w-100">Rp. {{formatPrice(parseInt(jumlah_rumpun.batas_atas_belanja))}},-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-12">
            <div class="card iq-mb-3 text-white bg-danger">
              <div class="iq-card-body">
                <div class="text-right"> 
                  <div>
                    <h6 class="mb-0  text-white">Belanja Sub kegiatan Tidak Wajar Menurut ASB </h6>
                    <span class="h3 text-white mb-0 counter d-inline-block w-100"  >
                      Rp. {{formatPrice(parseInt(jumlah_rumpun.selisih_asb))}},-</span>
                       
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div> 
    <div class="row">

      <div class="col-md-12">
        <div class="iq-card mr-2 ml-3 "> 
                        <div class="iq-card-body">
                            <ul class="nav nav-tabs" id="myTab-1" role="tablist">
                              <li class="nav-item">
                                 <a class="nav-link active" @click="loading()" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Rekap Berdasarkan Rumpun</a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" @click="loading()" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Daftar Belanja Sub kegiatan Tidak Wajar Menurut ASB</a>
                              </li>
                              <!-- <li class="nav-item">
                                 <a class="nav-link" @click="loading()" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
                              </li> -->
                           </ul>
                           <div class="tab-content" id="myTabContent-2">
                              <div class="tab-pane fade active show" id="home" role="tabpanel" aria-labelledby="home-tab"> 
                                   <RekapRumpun :rs_rumpun = rs_rumpun></RekapRumpun>
                              </div>
                              <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"> 
                                  <DaftarTidakWajar :daftar_tidak_wajar = daftar_tidak_wajar></DaftarTidakWajar>
                              </div>
                              <!-- <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                              </div> -->
                           </div>
                        </div>
                     </div>
      </div>

    </div>
    <div class="row ml-3 mr-3">
     
    </div>
  </div>
</template>
<script>

  import Breadcrumb from '../mixins/Breadcrumb'
  import Base from '../mixins/Base'
  import axios from '../config/Axios'
  import {
    mapMutations,
    mapState
  } from 'vuex'
  import BarChart from './home/BarChart.vue'
import RekapRumpun from './home/RekapRumpun.vue'
import DaftarTidakWajar from './home/DaftarTidakWajar.vue'

  export default {
    mixins: [Breadcrumb, Base],
    data() {
      return {
        rs_rumpun: [],
        jm_kegiatan : {},
        jumlah_rumpun : {},
        daftar_tidak_wajar : []
      };
    },
    mounted() {
      this.Loading();
      this.cek_token();
      this.cek_akses();
      this.openLoading();
      this.seter_Breadcrumb([{
        menu: "Home",
        link: "/home"
      }], {
        name: "Home",
        icon: "fa fa-home"
      });
      this.cekNotifikasi();
      this.rumpun();
      this.kegiatan();
      this.get_jumlah_rumpun();
      this.get_tidak_wajar();
    },
    computed: {
      ...mapState({})
    },
    methods: {
      ...mapMutations({}),
      formatPrice(value_2) {
        let val = (value_2 / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      loading(){
        this.Loading();
      },
      async rumpun() {
        const self = this;
        await axios({
            method: 'PUT',
            url: 'api/dashboard/rumpun',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rs_rumpun = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async kegiatan() {
        const self = this;
        await axios({
            method: 'PUT',
            url: 'api/dashboard/jm_kegiatan',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.jm_kegiatan = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_jumlah_rumpun() {
        const self = this;
        await axios({
            method: 'PUT',
            url: 'api/dashboard/jumlah_rumpun',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.jumlah_rumpun = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_tidak_wajar() {
        const self = this;
        await axios({
            method: 'PUT',
            url: 'api/dashboard/tidak_wajar',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.daftar_tidak_wajar = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    },
    components: {
    BarChart,
    RekapRumpun,
    DaftarTidakWajar
}
  }
</script>
<style>
  #sidebar-scrollbarx {
    overflow-y: auto;
    scrollbar-color: var(--iq-primary) var(--iq-color-light);
    scrollbar-width: thin;

  }
  .bg_green{
    background: rgba(36, 160, 113, 0.658)
    ;
  }
</style>
