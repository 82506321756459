<template>
    <div class="center">
        <vs-dialog not-close v-model="dataUser" width='500px' prevent-close> 
            <div class=" ">
                <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                        <h4 class="card-title">Update Password </h4>
                    </div>
                </div>
                <div class="iq-card-body"> 
                        <div class="form-group">
                            <label for="email">Username </label>
                            <input type="text" class="form-control" v-model="edit.username" disabled="">
                        </div>
                        <div class="form-group">
                            <label for="email">Password </label>
                            <input type="password" class="form-control" v-model="edit.password" name="password"
                                v-validate="'required|min:6'">
                            <span class="text-danger">{{ errors.first('password') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="email"> </label>
                            <input type="password" class="form-control" v-model="edit.password2" name="password_"
                                v-validate="'required|min:6'" placeholder="Ulangi Input Password">
                            <span class="text-danger">{{ errors.first('password_') }}</span>
                        </div>

                        <button   class="btn btn-primary btn-sm mr-1" @click="simpan(edit)"
                            v-if="edit.password == edit.password2"><i class="fa fa-save"></i>Submit</button>
                        <button   class="btn iq-bg-danger btn-sm ml-1" @click="close()"> <i
                                class="fa fa-close"></i>cancle</button> 
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
    // import axios from '../../../config/Axios'
    import Base from '../../../mixins/Base'
    export default {
        mixins: [Base],
        data() {
            return {

            }
        },
        emits: ["CloseButton", "UpdatePassword"],
        props: {
            active_password: {},
            user_key: {},
            rs_edit: {},
            level: []
        },
        computed: {
            dataUser() {
                return this.active_password;
            },
            edit() {
                return this.rs_edit;
            },
            get_level() {
                return this.level;
            }
        },
        mounted() {

        },
        methods: {
            simpan(edit) { 
                    this.$validator.validateAll().then(result => {
                        if (result) {
                            this.$emit("UpdatePassword", edit);
                            this.$emit("CloseButton", false);
                        }
                    }) 
            },
            close() {
                this.$emit("CloseButton", false);
            },
        }

    }
</script>

<style>

</style>