<template>
  <div>
    {{urls}}
  </div>
</template>

<script>
  
  import Breadcrumb from '../mixins/Breadcrumb'
  export default {
    mixins: [Breadcrumb],
    data() {      
      return {
        Breadcrumb: [{
            menu: "About",
            link: "/aaa"
          },
          {
            menu: "App Js",
            link: "/link_aja"
          }
        ],
        app_name: {
          name: "About",
          icon: "fa fa-user"
        },
        urls  : ""
      }
    },
    mounted() { 
      this.get_url();
      this.cekNotifikasi();
      this.seter_Breadcrumb(this.Breadcrumb, this.app_name) 
    },
    computed: {

    },
    methods: {
      get_url(){
        const url =  window.location.toString(); 
        this.urls = url.split("#", 2)[1];
      }

    }
  }
</script>
