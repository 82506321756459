<template>
    <div>
        <div class="wrapper">
            <!-- Sidebar  -->
            <sidebar></sidebar>
            <!-- TOP Nav Bar -->
            <HeaderApp></HeaderApp>
            <!-- TOP Nav Bar END -->
            <!-- Page Content  -->
            <div id="content-page" class="content-page">
                <div>
                    <router-view />
                </div>
            </div>
        </div>
        <!-- Wrapper END -->
        <!-- Footer -->
        <FooterApp></FooterApp>

    </div>

</template>

<style>

</style>
<script>
    import sidebar from '../themes/sidebar/Index.vue'
    import HeaderApp from '../themes/header/Index.vue'
    import FooterApp from '../themes/footer/Index.vue'
    import Base from '../mixins/Base';

    export default {
        mixins : [Base],
        components: {
            sidebar,
            HeaderApp,
            FooterApp
        },
        data() {
            return {
                Title: "Dashboard APP"
            }
        },
        mounted() {  
            this.cek_token();
        },
        methods: {}
    }
</script>