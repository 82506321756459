import Vue from 'vue'
import Vuex from 'vuex'
import Breadcrumb from './themes/Breadcrumb';
import Information from './notification/Information';
import Messages from './notification/Messages'
import Users from './acl/User'
import AclLevel from './acl/Level';
import Profile from './acl/Profile' 
import InputASBN from './asbn/Input'

Vue.use(Vuex)

export default new Vuex.Store({ 
  modules: {
    Breadcrumb,
    Information,
    Messages,
    Users,
    AclLevel,
    Profile, 
    InputASBN,
   },
  
})



// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
