export default {
    mamespaced : true,
    state: { 
        userData: {}, 
    },
    mutations: { 
        SetUser(state, value) {
            state.userData = value 
        }
    },
    actions: {
   },
   
}
