<template>
    <div class="center">
        <vs-dialog not-close v-model="dataUser" width='500px' prevent-close> 
            <div class=" ">
                <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                        <h4 class="card-title">Add Menu </h4>
                    </div>
                </div>
                <div class="iq-card-body">
                    <div>
                        <div class="form-group">
                            <label for="email">Username </label>
                            <input type="text" name="username" class="form-control" v-model="add_data.username"
                                @input="cek_username(add_data.username)" v-validate="'required|min:6'">
                            <p class="text-danger"><i>{{info}}</i></p>
                            <span class="text-danger">{{ errors.first('username') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="email">Nama </label>
                            <input type="text" class="form-control" v-model="add_data.nm_lengkap"
                                v-validate="'required'" name="nama_lengkap">
                            <span class="text-danger">{{ errors.first('nama_lengkap') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Level</label>
                            <select class="form-control" id="exampleFormControlSelect1" name="level"
                                v-model="add_data.level_key" v-validate="'required'" :placeholder="add_data.level_name">
                                <option v-for="get_level in get_level" v-bind:key="get_level.level_key"
                                    v-bind:value="get_level.level_key"
                                    :selected="get_level.level_key == add_data.level_key ">{{get_level.level_name}}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors.first('level') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="email">No Hp </label>
                            <input type="text" class="form-control" v-model="add_data.no_hp">
                        </div>
                        <div class="form-group">
                            <label for="email">Email address:</label>
                            <input type="email" class="form-control" id="email1" v-model="add_data.email">
                        </div>


                        <button v-if="disable_input !='disabled'"   class="btn btn-primary btn-sm mr-1"
                            @click="simpan(add_data)"><i class="fa fa-save"></i>Submit</button>
                            
                        <button  class="btn iq-bg-danger btn-sm ml-1" @click="close()"> <i
                                class="fa fa-close"></i>cancle</button>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
    import axios from '../../../config/Axios'
    import Base from '../../../mixins/Base'
    export default {
        mixins: [Base],
        data() {
            return {
                info: "",
                disable_input: ""
            }
        },
        emits: ["CloseButton", "AddUser"],
        props: {
            active_add: {},
            user_key: {},
            rs_add: {},
            level: [],

        },
        computed: {
            dataUser() {
                return this.active_add;
            },
            add_data() {
                return this.rs_add;
            },
            get_level() {
                return this.level;
            }
        },
        mounted() {
            this.info = '';

        },
        methods: {
            simpan(edit) {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.$emit("AddUser", edit);
                        this.$emit("CloseButton", false);
                    }
                })

            },
            close() {
                this.$emit("CloseButton", false);
            },
            cek_username(username) {
                const self = this;
                this.$validator.validateAll().then(result => { 
                    if (result) {
                        axios({
                                method: 'PUT',
                                url: 'api/acl/user/cek_user',
                                data: {
                                    username: username,
                                },
                                headers: {
                                    'Authorization': self.isAuthenticated
                                }
                            })
                            .then(function (response) {
                                self.info = response.data.message;
                                console.log(response.data);
                                if (response.data.result.st == '1') {
                                    self.disable_input = "disabled";
                                }else{
                                    self.disable_input = "";
                                }

                            }).catch(err => {
                                self.pesan = err.message;
                                self.notification('warning', "Error", err.message);
                            });
                    }
                })

            },
        }

    }
</script>

<style>

</style>
