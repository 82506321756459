<template>
   <div>
      <div class="iq-sidebar">
         <div class="iq-sidebar-logo d-flex justify-content-between">
            <a href="index.html">
               <div class="iq-light-logo">
                  <img src="images/logo.gif" class="img-fluid" alt="">
               </div>
               <div class="iq-dark-logo">
                  <img src="images/logo-dark.gif" class="img-fluid" alt="">
               </div>
               <span>ASB</span>
            </a>
            <div class="iq-menu-bt-sidebar">
               <div class="iq-menu-bt align-self-center">
                  <div class="wrapper-menu">
                     <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
                     <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
                  </div>
               </div>
            </div>
         </div>
         <!-- class="active" -->
         <div id="sidebar-scrollbar">
            <nav class="iq-sidebar-menu">
               <ul id="iq-sidebar-toggle" class="iq-menu">
                  <li class="iq-menu-title">
                     <i class="ri-subtract-line"></i><span>App Module</span>
                  </li>


                  <div v-for="menu in menu" v-bind:key="menu.id">

                     <div v-if="menu.st == '0'  "> 
                        <li v-if="urls == menu.url" class="active" @click="ariaExpande()">
                        <a :href="'#'+menu.url" class="iq-waves-effect" @click="get_url(menu.url)"  >
                           <i :class="menu.icon "></i><span>{{menu.nm_menu}} 
                              </span></a>
                     </li>
                     <li v-else>
                        <a :href="'#'+menu.url" class="iq-waves-effect collapsed" @click="get_url(menu.url)"  >
                           <i :class="menu.icon "></i><span>{{menu.nm_menu}}  
                              </span></a>
                     </li>
                     </div>  
                     <li v-if="menu.st =='1'">
                        <a :href="'#menu_key'+menu.id_menu" :class="collapsed" data-toggle="collapse"
                           :aria-expanded="expanded"><i :class="menu.icon"></i><span>{{menu.nm_menu}}</span><i
                              class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                              
                        <ul :id="'menu_key'+menu.id_menu" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle"
                        v-for="sub_menu in menu.sub_menu" v-bind:key="sub_menu.id"
                        > 
                              <li v-if="urls == sub_menu.url_sub_menu" class="iq-sidebar-toggle"  >
                              <a :href="'#'+sub_menu.url_sub_menu" @click="get_url(sub_menu.url_sub_menu)">
                                 <i class="ri-git-commit-line"></i><b class="text-primary">  {{sub_menu.nm_sub_menu}}   
                                 </b>
                              </a>
                           </li> 
                           <li v-else>
                              <a :href="'#'+sub_menu.url_sub_menu" @click="get_url(sub_menu.url_sub_menu)">
                                 <i class="ri-git-commit-line"></i>{{sub_menu.nm_sub_menu}} 
                              </a>
                           </li> 
                           

                        </ul>
                     </li>

                  </div>  
               </ul>
            </nav>
            <div class="p-3"></div>
         </div>
      </div>
   </div>
</template>


<script>
   import Base from '../../mixins/Base';
   import axios from '../../config/Axios'
   export default {
      mixins: [Base],
      data() {
         return {
            menu: [],
            urls: "",
            expanded : "false",
            collapsed : "iq-waves-effect collapsed"
         }
      },
      mounted() {
         this.cek_token();
         this.getSideBar();
      },
      computed: {

      },
      methods: {
         ariaExpande(){ 
            this.expanded ='false'
            this.collapsed = "iq-waves-effect collapsed"
         },
         get_url(url) {
            this.urls = url;
            this.expanded ='false' 
         },
         async getSideBar() {
            const self = this;
            await axios({
                    method: 'PUT',
                    url: 'api/aut/sidebar', 
                    headers: {
                        'Authorization': self.isAuthenticated 
                    }
                })
                .then(function (response) { 
                   self.menu = response.data.result;
                }).catch(err => {
                    self.pesan = err.message;
                    return self.$router.push({
                        name: 'Login'
                    });
                });

        },

      }
   }
</script>

<style>
 etr {
        white-space: pre;
    }
</style>