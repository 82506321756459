<template>
    <div class="center">
        <vs-dialog not-close overflow-hidden v-model="subOpen" width='600px' prevent-close>
            <div class="iq-card-block iq-card-stretch ">
                <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                        <h4 class="card-title"><i :class="DataMenu.icon + ' mr-2'"></i>{{DataMenu.nm_menu}}</h4>
                    </div>
                    <div class="iq-card-header-toolbar d-flex align-items-center">
                        <div class="dropdown">
                            <button class="btn iq-bg-primary btn-sm ml-1 float-left"
                                @click="PressSub('c',DataMenu.id_menu)"> <i class="fa fa-plus"></i> Add Sub
                                Menu</button>
                            <button class="btn iq-bg-danger btn-sm ml-1 float-left" @click="close()"> <i
                                    class="fa fa-close"></i></button>
                        </div>
                    </div>
                </div>
                <div class="iq-card-body ">
                    <div v-for="rs_menu in DataMenu.menu_sub" v-bind:key="rs_menu.id_menu_sub">
                        <div class="iq-card iq-card-block iq-card-stretch mb-1">
                            <div class="iq-card-body p-1">
                                <div class="d-flex align-items-center">
                                    <div class="media-support-info ml-3">
                                        <h6>{{rs_menu.nm_menu}}</h6>
                                        <p class="mb-0 font-size-12">{{rs_menu.url}}</p>
                                    </div>
                                    <div class="iq-card-header-toolbar">
                                        <div class="badge badge-pill badge-primary dark-icon  ml-1 "
                                            v-if="rs_menu.st =='1'"><i class="fa fa-bell-o mr-1"></i>
                                        </div>
                                        <div class="badge badge-pill badge-warning dark-icon  ml-1  " v-else><i
                                                class="fa fa-bell-slash mr-1 ml-1"></i>

                                        </div>
                                        <div class="badge badge-pill badge-info dark-icon ml-1"
                                            @click="PressSub('u', rs_menu)"><i class="fa fa-pencil mr-1 ml-1"></i></div>
                                        <div class="badge badge-pill badge-danger dark-icon  ml-1"
                                            @click="DeleteData(rs_menu)"><i class="fa fa-trash mr-1 ml-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="con-content">
                <AddSub :active2=active2 :act=act @CloseSub=CloseSub @suMenuParse=suMenuParse :sub_menu=sub_menu>
                </AddSub>
            </div>
        </vs-dialog>
    </div>
</template>
<script>
    import AddSub from './Add.vue';
    import Base from '../../../../mixins/Base';
    import axios from '../../../../config/Axios'
    export default {
        mixins: [Base],
        components: {
            AddSub
        },
        data() {
            return {
                active: false,
                active2: false,
                sub_menu: {},
                act: "",
                vs: {}
            }
        },
        props: {
            openSub: {},
            data_menu: {}
        },
        emits: ["CloseButton"],
        computed: {
            subOpen() {
                return this.openSub;
            },
            DataMenu: {
                get: function () {
                    return this.data_menu;
                },
                set: function (newValue) {
                    return this.data_menu = newValue;
                }

            }
        },
        mounted() {
            this.cek_akses();
        },
        methods: {
           async DeleteData(rs_menu) {
                if (confirm("Apakah anda akan menghapus data ini") == true) {
                    this.Loading();
                    const self = this;
                    await axios({
                            method: 'POST',
                            url: 'api/acl/menu/delete_sub',
                            data: {
                                id_menu: rs_menu.id_menu,
                                menu_key : rs_menu.id_menu_sub
                            },
                            headers: {
                                'Authorization': self.isAuthenticated
                            }
                        })
                        .then(function (response) {
                            self.notification('', response.data.status, response.data.message);
                            self.DataMenu = response.data.result;
                             
                        }).catch(err => {
                            self.pesan = err.message;
                            self.notification('warning', "Error", err.message);
                        });
                }
            },
            CloseSub() {
                this.active2 = false;
            },
            PressSub(val, val2) {
                if (val == 'c') {
                    this.sub_menu = {
                        id_menu: val2,
                        id_menu_sub: "",
                        nm_menu: "",
                        url: "",
                        menu_key: "",
                        order: "",
                        st: ""
                    }
                } else {
                    this.sub_menu = val2;
                }
                this.act = val;
                this.active2 = true;

            },
            suMenuParse(val) {
                this.openLoading();
                this.DataMenu = val; 
            },
            simpan() {},
            close() {
                this.$emit("CloseButton", '');
            }
        }
    }
</script>