<template>
    <div class="wrapper">
        <div class="container-fluid p-0">
            <div class="row no-gutters">
                <div class="col-sm-12 text-center">
                    <div class="iq-error">
                        <img src="images/error/acces_denied.png" class="img-fluid iq-error-img" alt=""
                            style="width :400px">
                        <h2 class="mb-0">Oops! This Acces Denied.</h2>
                        <p>The requested no akcces in page <br>
                            <router-link to="/home">
                                <button class="btn btn-primar">
                                    <i class="ri-home-4-line"></i> <b>Back to Home</b>
                                </button></router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>

<style>

</style>