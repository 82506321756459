<template>
   <div class="container-fluid">
      <div class="row">
         <div class="col-md-6 iq-todo-page">
            <form class="position-relative">
               <div class="form-group mb-0">
                  <input type="text" class="form-control todo-search mt-2" id="exampleInputEmail002"
                     placeholder="Search" v-model="search" @input="get_user()">
                  <a class="search-link" href="#"><i class="ri-search-line"></i></a>
               </div>
            </form>
         </div>
         <div class="col-md-6">
            <button class="btn btn-outline-primary mb-3 mt-2  float-right" @click="add_data()"><i
                  class="fa fa-plus"></i>Add User </button>
         </div>
      </div>
      <div class="row">
         <div class="col-lg-6" v-for="rs_user in rs_user" v-bind:key="rs_user.user_key">
            <div class="iq-card iq-card-block iq-card-stretch mb-2 ">
               <div class="iq-card-body p-1 pr-3 pl-3">
                  <div class="d-flex align-items-center p-1">
                     <vs-avatar badge badge-color="success" v-if="rs_user.status_user == 'Aktif'">
                        <img :src="rs_user.foto" alt="">
                        <template #badge>
                           <i class='fa fa-check'></i>
                        </template>
                     </vs-avatar>
                     <vs-avatar badge badge-color="danger" v-if="rs_user.status_user == 'Non Aktif'">
                        <img :src="rs_user.foto" alt="">
                        <template #badge>
                           <i class='fa fa-frown-o'></i>
                        </template>
                     </vs-avatar>
                     <div class="media-support-info ml-3">
                        <h6>{{rs_user.username}}</h6>
                        <p class="mb-0 font-size-12">{{rs_user.nm_lengkap}}</p>
                        <p class="mb-0 font-size-12"><b>{{rs_user.email}}</b></p>
                        <p class="mb-0 font-size-12"><b> {{rs_user.level_name}}</b></p>
                        <!-- <p class="mb-0 font-size-12">Last Login 24 jan, 2020</p> -->
                     </div>
                     <div class=" ml-3 mr-2">
                        <h6 class="text-dark">Aksi</h6>
                        <div v-if="rs_user.status_user == 'Aktif' ">
                           <div class="badge badge-pill badge-primary dark-icon ml-1"
                              @click="enable_disable(rs_user.user_key)"><i class="fa fa-smile-o"></i></div>
                        </div>
                        <div v-else>
                           <div class="badge badge-pill badge-secondary btn-sm ml-1"
                              @click="enable_disable(rs_user.user_key)"><i class="fa fa-frown-o"></i></div>
                        </div>

                        <div class="badge badge-pill badge-info dark-icon ml-1" @click="edit_data(rs_user.user_key)"><i
                              class="fa fa-pencil  "></i>
                        </div>
                        <div class="badge badge-pill badge-warning dark-icon ml-1"
                           @click="update_password(rs_user.user_key)"><i class="fa fa-lock "></i>
                        </div>
                     </div>
                  </div>
               </div>
            </div>


         </div>

      </div>
      <div class="row">
         <div class="col-lg-12">
            <div class="float-right">
               <vs-pagination v-model="page" :length="lenght" @input="load_data" class="float-right" />
            </div>
         </div>
      </div>


      <edit :active_edit=active_edit @CloseButton=CloseButton @UpdateUser=UpdateUser :rs_edit=rs_edit :level=level_lis>
      </edit>
      <add :active_add=active_add @CloseButton=CloseButton @AddUser=AddUser :level=level_lis :rs_add=rs_add>
      </add>
      <password-u :active_password=active_password @CloseButton=CloseButton @UpdatePassword=UpdatePassword
         :rs_edit=rs_edit></password-u>


   </div>
</template>
<script>
   import axios from '../../../config/Axios';
   import Breadcrumb from '../../../mixins/Breadcrumb';
   import Base from '../../../mixins/Base';
   import Edit from './Edit.vue';
   import Add from './Add.vue';
   import PasswordU from './PasswordU.vue';
   export default {
      components: {
         Edit,
         Add,
         PasswordU
      },
      mixins: [Breadcrumb, Base],
      data: () => ({
         page: 1,
         rs_user: [],
         search: "",
         lenght: 1,
         active_edit: false,
         active_add: false,
         active_password: false,
         rs_edit: {},
         level_lis: [],
         rs_add: {}
      }),
      mounted() {
         this.cek_token();
         this.openLoading();
         this.seter_Breadcrumb([{
            menu: "Home",
            link: "/home"
         }, {
            menu: "User",
            link: "/acl_user"
         }], {
            name: "User List",
            icon: "fa fa-users"
         });
         this.cek_akses();
         this.get_user();
         this.level();

      },
      methods: {
         CloseButton(val) {
            this.active_edit = val;
            this.active_add = val;
            this.active_password = val;
         },
         async UpdateUser(val) {
            const self = this;
            await axios({
                  method: 'POST',
                  url: 'api/acl/user/update_profile',
                  data: val,
                  headers: {
                     'Authorization': self.isAuthenticated
                  }
               })
               .then(function (response) {
                  self.notification('', response.data.status, response.data.message);
                  self.get_user();

               }).catch(err => {
                  self.pesan = err.message;
                  self.notification('warning', "Error", err.message);
               });
         },
         async AddUser(val) {
            //update_profile
            const self = this;
            await axios({
                  method: 'POST',
                  url: 'api/acl/user/add',
                  data: val,
                  headers: {
                     'Authorization': self.isAuthenticated
                  }
               })
               .then(function (response) {
                  self.notification('', response.data.status, response.data.message);
                  self.get_user();


               }).catch(err => {
                  self.pesan = err.message;
                  self.notification('warning', "Error", err.message);
               });
         },

         async UpdatePassword(val) {
            //update_profile
            const self = this;
            await axios({
                  method: 'POST',
                  url: 'api/acl/user/update_password',
                  data: {
                     "user_key": val.user_key,
                     "password": val.password
                  },
                  headers: {
                     'Authorization': self.isAuthenticated
                  }
               })
               .then(function (response) {
                  self.notification('', response.data.status, response.data.message);
                  self.get_user();
               }).catch(err => {
                  self.pesan = err.message;
                  self.notification('warning', "Error", err.message);
               });

         },
         async enable_disable(enable_disable) {
            const self = this;
            await axios({
                  method: 'POST',
                  url: 'api/acl/user/disable',
                  data: {
                     user_key: enable_disable,
                  },
                  headers: {
                     'Authorization': self.isAuthenticated
                  }
               })
               .then(function (response) {
                  self.notification('', response.data.status, response.data.message);
                  self.get_user()

               }).catch(err => {
                  self.pesan = err.message;
                  self.notification('primary', "Error", err.message);
               });
         },
         load_data() {
            this.Loading();
            this.get_user()
         },
         async get_user() {
            const self = this;
            await axios({
                  method: 'PUT',
                  url: 'api/acl/user/list',
                  data: {
                     offset: self.page,
                     cari: self.search
                  },
                  headers: {
                     'Authorization': self.isAuthenticated
                  }
               })
               .then(function (response) {
                  self.lenght = parseInt(response.data.result.lenght);
                  self.rs_user = response.data.result.rs;

               }).catch(err => {
                  self.pesan = err.message;
                  self.notification('warning', "Error", err.message);
               });
         },

         async update_password(user_key) {
            this.active_password = true;
            this.id_user = user_key;
            const self = this;
            await axios({
                  method: 'PUT',
                  url: 'api/acl/user/edit',
                  data: {
                     user_key: user_key
                  },
                  headers: {
                     'Authorization': self.isAuthenticated
                  }
               })
               .then(function (response) {
                  self.rs_edit = response.data.result;
               }).catch(err => {
                  self.pesan = err.message;
                  self.notification('warning', "Error", err.message);
               });
         },

         async level() {
            const self = this;
            await axios({
                  method: 'PUT',
                  url: 'api/acl/user/level',
                  headers: {
                     'Authorization': self.isAuthenticated
                  }
               })
               .then(function (response) {
                  self.level_lis = response.data.result;
               }).catch(err => {
                  self.pesan = err.message;
                  self.notification('warning', "Error", err.message);
               });
         },
         add_data() {
            this.active_add = true;
            this.rs_add = {}
         },
         async edit_data(user_key) {
            this.active_edit = true;
            this.id_user = user_key;
            const self = this;
            await axios({
                  method: 'PUT',
                  url: 'api/acl/user/edit',
                  data: {
                     user_key: user_key
                  },
                  headers: {
                     'Authorization': self.isAuthenticated
                  }
               })
               .then(function (response) {
                  self.rs_edit = response.data.result;
               }).catch(err => {
                  self.pesan = err.message;
                  self.notification('warning', "Error", err.message);
               });
         },

      },
      computed: {

      }
   }
</script>