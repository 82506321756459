<template>
  <div>
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-6 iq-todo-page">
          <form class="position-relative">
            <div class="form-group mb-0">
              <input type="text" class="form-control todo-search mt-2" id="exampleInputEmail002" placeholder="Search"
                v-model="search" @input="get_menu()">
              <a class="search-link" href="#"><i class="ri-search-line"></i></a>
            </div>
          </form>
        </div>
        <div class="col-md-6">
          <button class="btn btn-outline-primary mb-3 mt-2  float-right" @click="press('c', {})"><i
              class="fa fa-plus"></i>Add Menu </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 " v-for="rs_menu in rs_menu" v-bind:key="rs_menu.id_menu">
          <div class="iq-card iq-card-block iq-card-stretch mb-1">
            <div class="iq-card-body p-1">
              <div class="d-flex align-items-center">
                <div class="icon iq-icon-box">
                  <i :class="'fa ' + rs_menu.icon" aria-hidden="true"></i>
                </div>
                <div class="media-support-info ml-3">
                  <h6>{{rs_menu.nm_menu}}</h6>
                  <p class="mb-0 font-size-12">{{rs_menu.url}}</p>
                </div>
                <div class="iq-card-header-toolbar">
                  <div class="badge badge-pill badge-primary dark-icon  ml-1" v-if="rs_menu.st =='1'"><i
                      class="fa fa-bell-o mr-1"></i>Enable
                  </div>
                  <div class="badge badge-pill badge-warning dark-icon  ml-1" v-else><i
                      class="fa fa-bell-slash mr-1"></i>Disable

                  </div>
                  <div class="badge badge-pill badge-primary dark-icon ml-1" @click="openSubMenu(rs_menu)"><i
                      class="fa fa-list mr-1"></i>Sub Menu
                  </div>
                  <div class="badge badge-pill badge-info dark-icon ml-1" @click="press('u', rs_menu)"><i
                      class="fa fa-pencil mr-1"></i>Edit</div>
                  <div class="badge badge-pill badge-danger dark-icon  ml-1" @click="DeleteData(rs_menu)"><i
                      class="fa fa-trash mr-1"></i>Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="float-right">
            <vs-pagination v-model="page" :length="lenght" @input="load_data" class="float-right" />
          </div>
        </div>
      </div>
    </div>
    <add :button=button @CloseButton=CloseButton :data_menu=data_menu></add>
    <sub-menu :openSub=openSub @CloseButton=CloseButton :data_menu=data_menu></sub-menu>
  </div>
</template>
<script>
  import axios from '../../../config/Axios'
  import Breadcrumb from '../../../mixins/Breadcrumb'
  import Base from '.././../../mixins/Base'
  import Add from '../menu/Add.vue'
  import SubMenu from './submenu/index.vue'

  export default {
    components: {
      Add,
      SubMenu
    },
    mixins: [Breadcrumb, Base],
    data() {
      return {
        page: 1,
        lenght: 1,
        rs_menu: [],
        button: {},
        search: "",
        data_menu: {},
        openSub: false
      }
    },
    mounted() {
      this.cek_token();
      this.cek_akses();
      this.seter_Breadcrumb([{
          menu: "Home",
          link: "/home"
        },
        {
          menu: "Menu App",
          link: "/acl_menu"
        }
      ], {
        name: "Menu",
        icon: "fa fa-list"
      });
      this.get_menu();
    },
    computed: {

    },
    methods: {
      async get_menu() {
        this.Loading();
        const self = this;
        await axios({
            method: 'PUT',
            url: 'api/acl/menu/list',
            data: {
              offset: self.page,
              cari: self.search
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.lenght = parseInt(response.data.result.lenght);
            self.rs_menu = response.data.result.rs;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      load_data() {
        this.get_menu();
      },
      press(val, val2) {
        console.log(val2)
        this.data_menu = val2;
        if (val == 'c') {
          this.button = {
            c: true,
            u: false,
          }
        } else {
          this.button = {
            c: false,
            u: true,
          }
        }

      },
      openSubMenu(rs_menu) {
        this.openSub = true;
        this.data_menu = rs_menu
      },
      async DeleteData(val) {
        if (confirm("Apakah anda akan menghapus data ini") == true) {
          this.Loading();
          const self = this;
          await axios({
              method: 'POST',
              url: 'api/acl/menu/delete',
              data: {
                id_menu: val.id_menu
              },
              headers: {
                'Authorization': self.isAuthenticated
              }
            })
            .then(function (response) {
              self.notification('', response.data.status, response.data.message);
              self.get_menu();
            }).catch(err => {
              self.pesan = err.message;
              self.notification('warning', "Error", err.message);
            });
        }


      },
      CloseButton(val) {
        this.openSub = false;
        this.button = {
          c: false,
          u: false,
          val
        }
      }
    }
  }
</script>

<style>

</style>