<template>
  <div class="wrapper">
              <div class="container-fluid p-0">
                  <div class="row no-gutters">
                      <div class="col-sm-12 text-center">
                          <div class="iq-error">
                              <img src="images/error/token_habis.png" class="img-fluid iq-error-img" alt="" style="width :400px">
                              <h2 class="mb-0">Oops! This session out...</h2>
                              <p>please login again<br>
                                   <router-link  to="/login">
                                   <button class="btn btn-primar" >
                                       <i class="fa fa-sign-in"></i> <b>Back to login</b>
                                       </button></router-link> 
                                   </p>                         
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  </template> 
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>