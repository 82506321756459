import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../views/App.vue'
import Home from '../views/Home.vue'
import Login from '../auth/Login.vue'
import About from '../views/About.vue'
import User from '../auth/acl/user/Index.vue'
import Menu from '../auth/acl/menu/Index.vue'
import Level from '../auth/acl/level/Index.vue'
import PathNotFound from '../auth/Page_404.vue'
import AcessDenied from '../auth/AcessDenied.vue'
import lostToken from '../auth/TokenHabis.vue'
import CutAcces from '../auth/CutAcces.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/app',
    name: 'App',
    component: App,
    children: [{
        path: '/home',
        name: 'Home',
        component: Home
      }, {
        path: '/about',
        name: 'About',
        component: About
      },
      //Akses Control List
      {
        path: '/acl/user',
        name: 'User',
        component: User
      }, {
        path: '/acl/level',
        name: 'Level',
        component: Level
      }, {
        path: '/acl/menu',
        name: 'menu',
        component: Menu
      },
      //Profile 
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../auth/profile/Index.vue')
      },
      //----------------------------------------//
      //---------- LAPORAN ----------------//
      {
        path: '/laporan',
        name: 'laporan',
        component: () => import('../views/laporan/Index.vue') 
      },

      // ======================================//
      //== Modul Transaksi  Pelayanan Dasar ===//
      // ====================================== // 

      {
        path: '/layanandasar_pendidikan',
        name: 'layananDasaePendidikan',
        component: () => import('../views/transaksi/pelayanan_dasar/pendidikan/Index.vue'),
        children: [{
            path: '/layanandasar_pendidikan/',
            name: 'LP_Dashboard',
            component: () => import('../views/transaksi/pelayanan_dasar/pendidikan/dashboard/Index.vue'),
          },
          {
            path: '/layanandasar_pendidikan/program',
            name: 'LP_Program',
            component: () => import('../views/transaksi/pelayanan_dasar/pendidikan/program/Index.vue'),
          },
          {
            path: '/layanandasar_pendidikan/sub_kegiatan',
            name: 'LP_SubKeiatan',
            component: () => import('../views/transaksi/pelayanan_dasar/pendidikan/ListData.vue'),
          },
          {
            path: '/layanandasar_pendidikan/tidak_wajar',
            name: 'LP_TidakWajar',
            component: () => import('../views/transaksi/pelayanan_dasar/pendidikan/TidakWajar.vue'),
          }, {
            path: '/layanandasar_pendidikan/kegiatan',
            name: 'LP_Kegiatan',
            component: () => import('../views/transaksi/pelayanan_dasar/pendidikan/kegiatan/Index.vue'),
          }
        ]
      },
      {
        path: '/layanandasar_kesehatan',
        name: 'LayananDasarKesehatan',
        component: () => import('../views/transaksi/pelayanan_dasar/kesehatan/Index.vue'),
        children: [{
            path: '/layanandasar_kesehatan/',
            name: 'LK_Dashboard',
            component: () => import('../views/transaksi/pelayanan_dasar/kesehatan/dashboard/Index.vue'),
          },
          {
            path: '/layanandasar_kesehatan/program',
            name: 'LK_Program',
            component: () => import('../views/transaksi/pelayanan_dasar/kesehatan/program/Index.vue'),
          },
          {
            path: '/layanandasar_kesehatan/sub_kegiatan',
            name: 'LK_SubKeiatan',
            component: () => import('../views/transaksi/pelayanan_dasar/kesehatan/ListData.vue'),
          },
          {
            path: '/layanandasar_kesehatan/tidak_wajar',
            name: 'LK_TidakWajar',
            component: () => import('../views/transaksi/pelayanan_dasar/kesehatan/TidakWajar.vue'),
          }, {
            path: '/layanandasar_kesehatan/kegiatan',
            name: 'LK_Kegiatan',
            component: () => import('../views/transaksi/pelayanan_dasar/kesehatan/kegiatan/Index.vue'),
          }
        ]
      },
      {
        path: '/layanandasar_pupr',
        name: 'LayanandasarPUPR',
        component: () => import('../views/transaksi/pelayanan_dasar/pupr/Index.vue'),
        children: [{
          path: '/layanandasar_pupr/',
          name: 'LD_Dashboard',
          component: () => import('../views/transaksi/pelayanan_dasar/pupr/dashboard/Index.vue'),
        },
        {
          path: '/layanandasar_pupr/program',
          name: 'LD_Program',
          component: () => import('../views/transaksi/pelayanan_dasar/pupr/program/Index.vue'),
        },
        {
          path: '/layanandasar_pupr/sub_kegiatan',
          name: 'LD_SubKeiatan',
          component: () => import('../views/transaksi/pelayanan_dasar/pupr/ListData.vue'),
        },
        {
          path: '/layanandasar_pupr/tidak_wajar',
          name: 'LD_TidakWajar',
          component: () => import('../views/transaksi/pelayanan_dasar/pupr/TidakWajar.vue'),
        }, {
          path: '/layanandasar_pupr/kegiatan',
          name: 'LD_Kegiatan',
          component: () => import('../views/transaksi/pelayanan_dasar/pupr/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/layanandasar_prkp',
        name: 'layananDasaePRKP',
        component: () => import('../views/transaksi/pelayanan_dasar/prkp/Index.vue'),  
        children: [{
          path: '/layanandasar_prkp/',
          name: 'LDprkp_Dashboard',
          component: () => import('../views/transaksi/pelayanan_dasar/prkp/dashboard/Index.vue'),
        },
        {
          path: '/layanandasar_prkp/program',
          name: 'LDprkp_Program',
          component: () => import('../views/transaksi/pelayanan_dasar/prkp/program/Index.vue'),
        },
        {
          path: '/layanandasar_prkp/sub_kegiatan',
          name: 'LDprkp_SubKeiatan',
          component: () => import('../views/transaksi/pelayanan_dasar/prkp/ListData.vue'),
        },
        {
          path: '/layanandasar_prkp/tidak_wajar',
          name: 'LDprkp_TidakWajar',
          component: () => import('../views/transaksi/pelayanan_dasar/prkp/TidakWajar.vue'),
        }, {
          path: '/layanandasar_prkp/kegiatan',
          name: 'LDprkp_Kegiatan',
          component: () => import('../views/transaksi/pelayanan_dasar/prkp/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/layanandasar_kkpm',
        name: 'layananDasaeKKUMP',
        component: () => import('../views/transaksi/pelayanan_dasar/kkpm/Index.vue'),
        children: [{
          path: '/layanandasar_kkpm/',
          name: 'LDPkkpm_Dashboard',
          component: () => import('../views/transaksi/pelayanan_dasar/kkpm/dashboard/Index.vue'),
        },
        {
          path: '/layanandasar_kkpm/program',
          name: 'LDPkkpm_Program',
          component: () => import('../views/transaksi/pelayanan_dasar/kkpm/program/Index.vue'),
        },
        {
          path: '/layanandasar_kkpm/sub_kegiatan',
          name: 'LDP_SubKeiatan',
          component: () => import('../views/transaksi/pelayanan_dasar/kkpm/ListData.vue'),
        },
        {
          path: '/layanandasar_kkpm/tidak_wajar',
          name: 'LDPkkpm_TidakWajar',
          component: () => import('../views/transaksi/pelayanan_dasar/kkpm/TidakWajar.vue'),
        }, {
          path: '/layanandasar_kkpm/kegiatan',
          name: 'LDPkkpm_Kegiatan',
          component: () => import('../views/transaksi/pelayanan_dasar/kkpm/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/layanandasar_sosial',
        name: 'layananDasaeSosial',
        component: () => import('../views/transaksi/pelayanan_dasar/sosial/Index.vue'),
        children: [{
          path: '/layanandasar_sosial/',
          name: 'LDPS_Dashboard',
          component: () => import('../views/transaksi/pelayanan_dasar/sosial/dashboard/Index.vue'),
        },
        {
          path: '/layanandasar_sosial/program',
          name: 'LDPS_Program',
          component: () => import('../views/transaksi/pelayanan_dasar/sosial/program/Index.vue'),
        },
        {
          path: '/layanandasar_sosial/sub_kegiatan',
          name: 'LDPS_SubKeiatan',
          component: () => import('../views/transaksi/pelayanan_dasar/sosial/ListData.vue'),
        },
        {
          path: '/layanandasar_sosial/tidak_wajar',
          name: 'LDPS_TidakWajar',
          component: () => import('../views/transaksi/pelayanan_dasar/sosial/TidakWajar.vue'),
        }, {
          path: '/layanandasar_sosial/kegiatan',
          name: 'LDPS_Kegiatan',
          component: () => import('../views/transaksi/pelayanan_dasar/sosial/kegiatan/Index.vue'),
        }
      ]
      },
      // ======================================//
      //== Modul Transaksi  Pelayanan Dasar ===//
      // ====================================== // 
      {
        path: '/nonlayanandasar_disnaker',
        name: 'nonlayanandasar_disnaker',
        component: () => import('../views/transaksi/non_pelayanan_dasar/disnaker/Index.vue'),
        children: [{
          path: '/nonlayanandasar_disnaker/',
          name: 'LDP_disnaker_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/disnaker/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_disnaker/program',
          name: 'LDP_disnaker_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/disnaker/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_disnaker/sub_kegiatan',
          name: 'LDP_disnaker_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/disnaker/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_disnaker/tidak_wajar',
          name: 'LDP_disnaker_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/disnaker/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_disnaker/kegiatan',
          name: 'LDP_disnaker_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/disnaker/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_dp3a',
        name: 'nonlayanandasar_dp3a',
        component: () => import('../views/transaksi/non_pelayanan_dasar/dp3a/Index.vue'),
        children: [{
          path: '/nonlayanandasar_dp3a/',
          name: 'LDP_dp3a_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dp3a/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_dp3a/program',
          name: 'LDP_dp3a_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dp3a/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_dp3a/sub_kegiatan',
          name: 'LDP_dp3a_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dp3a/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_dp3a/tidak_wajar',
          name: 'LDP_dp3a_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dp3a/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_dp3a/kegiatan',
          name: 'LDP_dp3a_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dp3a/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_dkp',
        name: 'nonlayanandasar_dkp',
        component: () => import('../views/transaksi/non_pelayanan_dasar/dkp/Index.vue'),
        children: [{
          path: '/nonlayanandasar_dkp/',
          name: 'NDdkp_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dkp/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_dkp/program',
          name: 'NDdkp_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dkp/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_dkp/sub_kegiatan',
          name: 'ND_dkp_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dkp/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_dkp/tidak_wajar',
          name: 'ND_dkp_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dkp/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_dkp/kegiatan',
          name: 'ND_dkp_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dkp/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_pertanian',
        name: 'nonlayanandasar_pertanian',
        component: () => import('../views/transaksi/non_pelayanan_dasar/pertanian/Index.vue'),
        children: [{
          path: '/nonlayanandasar_pertanian/',
          name: 'NLDP_pertanian_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pertanian/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_pertanian/program',
          name: 'NLDP_pertanian_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pertanian/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_pertanian/sub_kegiatan',
          name: 'NLDP_pertanian_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pertanian/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_pertanian/tidak_wajar',
          name: 'NLDP_pertanian_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pertanian/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_pertanian/kegiatan',
          name: 'NLDP_pertanian_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pertanian/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_dlh',
        name: 'nonlayanandasar_dlh',
        component: () => import('../views/transaksi/non_pelayanan_dasar/dlh/Index.vue'),
        children: [{
          path: '/nonlayanandasar_dlh/',
          name: 'LDP_dlh_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dlh/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_dlh/program',
          name: 'LDP_dlh_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dlh/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_dlh/sub_kegiatan',
          name: 'LDP_dlh_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dlh/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_dlh/tidak_wajar',
          name: 'LDP_dlh_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dlh/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_dlh/kegiatan',
          name: 'LDP_dlh_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dlh/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_akps',
        name: 'nonlayanandasar_akps',
        component: () => import('../views/transaksi/non_pelayanan_dasar/akps/Index.vue'),
        children: [{
          path: '/nonlayanandasar_akps/',
          name: 'LDP_akps_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/akps/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_akps/program',
          name: 'LDP_akps_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/akps/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_akps/sub_kegiatan',
          name: 'LDP_akps_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/akps/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_akps/tidak_wajar',
          name: 'LDP_akps_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/akps/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_akps/kegiatan',
          name: 'LDP_akps_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/akps/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_pmd',
        name: 'nonlayanandasar_pmd',
        component: () => import('../views/transaksi/non_pelayanan_dasar/pmd/Index.vue'),
        children: [{
          path: '/nonlayanandasar_pmd/',
          name: 'LDP_pmd_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pmd/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_pmd/program',
          name: 'LDP_pmd_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pmd/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_pmd/sub_kegiatan',
          name: 'LDP_pmd_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pmd/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_pmd/tidak_wajar',
          name: 'LDP_pmd_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pmd/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_pmd/kegiatan',
          name: 'LDP_pmd_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/pmd/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_ppkb',
        name: 'nonlayanandasar_ppkb',
        component: () => import('../views/transaksi/non_pelayanan_dasar/ppkb/Index.vue'),
        children: [{
          path: '/nonlayanandasar_ppkb/',
          name: 'LDP_ppkb_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/ppkb/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_ppkb/program',
          name: 'LDP_ppkb_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/ppkb/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_ppkb/sub_kegiatan',
          name: 'LDP_ppkb_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/ppkb/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_ppkb/tidak_wajar',
          name: 'LDP_ppkb_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/ppkb/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_ppkb/kegiatan',
          name: 'LDP_ppkb_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/ppkb/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_perhubungan',
        name: 'nonlayanandasar_perhubungan',
        component: () => import('../views/transaksi/non_pelayanan_dasar/perhubungan/Index.vue'),
        children: [{
          path: '/nonlayanandasar_perhubungan/',
          name: 'LDP_perhubungan_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perhubungan/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_perhubungan/program',
          name: 'LDP_perhubungan_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perhubungan/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_perhubungan/sub_kegiatan',
          name: 'LDP_perhubungan_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perhubungan/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_perhubungan/tidak_wajar',
          name: 'LDP_perhubungan_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perhubungan/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_perhubungan/kegiatan',
          name: 'LDP_perhubungan_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perhubungan/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_kominfo',
        name: 'nonlayanandasar_kominfo',
        component: () => import('../views/transaksi/non_pelayanan_dasar/kominfo/Index.vue'),
        children: [{
          path: '/nonlayanandasar_kominfo/',
          name: 'LDP_kominfo_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kominfo/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_kominfo/program',
          name: 'LDP_kominfo_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kominfo/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_kominfo/sub_kegiatan',
          name: 'LDP_kominfo_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kominfo/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_kominfo/tidak_wajar',
          name: 'LDP_kominfo_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kominfo/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_kominfo/kegiatan',
          name: 'LDP_kominfo_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kominfo/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_kukm',
        name: 'nonlayanandasar_kukm',
        component: () => import('../views/transaksi/non_pelayanan_dasar/kukm/Index.vue'),
        children: [{
          path: '/nonlayanandasar_kukm/',
          name: 'LDP_kukm_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kukm/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_kukm/program',
          name: 'LDP_kukm_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kukm/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_kukm/sub_kegiatan',
          name: 'LDP_kukm_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kukm/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_kukm/tidak_wajar',
          name: 'LDP_kukm_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kukm/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_kukm/kegiatan',
          name: 'LDP_kukm_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kukm/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_penanaman_modal',
        name: 'nonlayanandasar_penanaman_modal',
        component: () => import('../views/transaksi/non_pelayanan_dasar/penanaman_modal/Index.vue'),
        children: [{
          path: '/nonlayanandasar_penanaman_modal/',
          name: 'LDP_penanaman_modal_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/penanaman_modal/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_penanaman_modal/program',
          name: 'LDP_penanaman_modal_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/penanaman_modal/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_penanaman_modal/sub_kegiatan',
          name: 'LDP_penanaman_modal_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/penanaman_modal/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_penanaman_modal/tidak_wajar',
          name: 'LDP_penanaman_modal_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/penanaman_modal/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_penanaman_modal/kegiatan',
          name: 'LDP_penanaman_modal_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/penanaman_modal/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_dispora',
        name: 'nonlayanandasar_dispora',
        component: () => import('../views/transaksi/non_pelayanan_dasar/dispora/Index.vue'),
        children: [{
          path: '/nonlayanandasar_dispora/',
          name: 'LDP_dispora_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dispora/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_dispora/program',
          name: 'LDP_dispora_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dispora/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_dispora/sub_kegiatan',
          name: 'LDP_dispora_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dispora/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_dispora/tidak_wajar',
          name: 'LDP_dispora_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dispora/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_dispora/kegiatan',
          name: 'LDP_dispora_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/dispora/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_statistik',
        name: 'nonlayanandasar_statistik',
        component: () => import('../views/transaksi/non_pelayanan_dasar/statistik/Index.vue'),
        children: [{
          path: '/nonlayanandasar_statistik/',
          name: 'LDP_statistik_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/statistik/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_statistik/program',
          name: 'LDP_statistik_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/statistik/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_statistik/sub_kegiatan',
          name: 'LDP_statistik_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/statistik/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_statistik/tidak_wajar',
          name: 'LDP_statistik_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/statistik/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_statistik/kegiatan',
          name: 'LDP_statistik_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/statistik/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_persandian',
        name: 'nonlayanandasar_persandian',
        component: () => import('../views/transaksi/non_pelayanan_dasar/persandian/Index.vue'),
        children: [{
          path: '/nonlayanandasar_persandian/',
          name: 'LDP_persandian_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/persandian/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_persandian/program',
          name: 'LDP_persandian_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/persandian/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_persandian/sub_kegiatan',
          name: 'LDP_persandian_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/persandian/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_persandian/tidak_wajar',
          name: 'LDP_persandian_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/persandian/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_persandian/kegiatan',
          name: 'LDP_persandian_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/persandian/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_kebudayaan',
        name: 'nonlayanandasar_kebudayaan',
        component: () => import('../views/transaksi/non_pelayanan_dasar/kebudayaan/Index.vue'),
        children: [{
          path: '/nonlayanandasar_kebudayaan/',
          name: 'LDP_kebudayaan_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kebudayaan/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_kebudayaan/program',
          name: 'LDP_kebudayaan_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kebudayaan/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_kebudayaan/sub_kegiatan',
          name: 'LDP_kebudayaan_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kebudayaan/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_kebudayaan/tidak_wajar',
          name: 'LDP_kebudayaan_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kebudayaan/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_kebudayaan/kegiatan',
          name: 'LDP_kebudayaan_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kebudayaan/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_perpustakaan',
        name: 'nonlayanandasar_perpustakaan',
        component: () => import('../views/transaksi/non_pelayanan_dasar/perpustakaan/Index.vue'),
        children: [{
          path: '/nonlayanandasar_perpustakaan/',
          name: 'LDP_perpustakaan_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perpustakaan/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_perpustakaan/program',
          name: 'LDP_perpustakaan_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perpustakaan/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_perpustakaan/sub_kegiatan',
          name: 'LDP_perpustakaan_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perpustakaan/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_perpustakaan/tidak_wajar',
          name: 'LDP_perpustakaan_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perpustakaan/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_perpustakaan/kegiatan',
          name: 'LDP_perpustakaan_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/perpustakaan/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/nonlayanandasar_kearsipan',
        name: 'nonlayanandasar_kearsipan',
        component: () => import('../views/transaksi/non_pelayanan_dasar/kearsipan/Index.vue'),
        children: [{
          path: '/nonlayanandasar_kearsipan/',
          name: 'LDP_kearsipan_Dashboard',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kearsipan/dashboard/Index.vue'),
        },
        {
          path: '/nonlayanandasar_kearsipan/program',
          name: 'LDP_kearsipan_Program',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kearsipan/program/Index.vue'),
        },
        {
          path: '/nonlayanandasar_kearsipan/sub_kegiatan',
          name: 'LDP_kearsipan_SubKeiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kearsipan/ListData.vue'),
        },
        {
          path: '/nonlayanandasar_kearsipan/tidak_wajar',
          name: 'LDP_kearsipan_TidakWajar',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kearsipan/TidakWajar.vue'),
        }, {
          path: '/nonlayanandasar_kearsipan/kegiatan',
          name: 'LDP_kearsipan_Kegiatan',
          component: () => import('../views/transaksi/non_pelayanan_dasar/kearsipan/kegiatan/Index.vue'),
        }
      ]
      },
      //Non Pemerintah Pilihan
      {
        path: '/pemerintah_pilihan_esdm',
        name: 'pemerintah_pilihan_esdm',
        component: () => import('../views/transaksi/pemerintah_pilian/esdm/Index.vue'),
        children: [{
          path: '/pemerintah_pilihan_esdm/',
          name: 'LDP_esdm_Dashboard',
          component: () => import('../views/transaksi/pemerintah_pilian/esdm/dashboard/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_esdm/program',
          name: 'LDP_esdm_Program',
          component: () => import('../views/transaksi/pemerintah_pilian/esdm/program/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_esdm/sub_kegiatan',
          name: 'LDP_esdm_SubKeiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/esdm/ListData.vue'),
        },
        {
          path: '/pemerintah_pilihan_esdm/tidak_wajar',
          name: 'LDP_esdm_TidakWajar',
          component: () => import('../views/transaksi/pemerintah_pilian/esdm/TidakWajar.vue'),
        }, {
          path: '/pemerintah_pilihan_esdm/kegiatan',
          name: 'LDP_esdm_Kegiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/esdm/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pemerintah_pilihan_dkp',
        name: 'pemerintah_pilihan_dkp',
        component: () => import('../views/transaksi/pemerintah_pilian/dkp/Index.vue'),
        children: [{
          path: '/pemerintah_pilihan_dkp/',
          name: 'LDP_dkp_Dashboard',
          component: () => import('../views/transaksi/pemerintah_pilian/dkp/dashboard/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_dkp/program',
          name: 'LDP_dkp_Program',
          component: () => import('../views/transaksi/pemerintah_pilian/dkp/program/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_dkp/sub_kegiatan',
          name: 'LDP_dkp_SubKeiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/dkp/ListData.vue'),
        },
        {
          path: '/pemerintah_pilihan_dkp/tidak_wajar',
          name: 'LDP_dkp_TidakWajar',
          component: () => import('../views/transaksi/pemerintah_pilian/dkp/TidakWajar.vue'),
        }, {
          path: '/pemerintah_pilihan_dkp/kegiatan',
          name: 'LDP_dkp_Kegiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/dkp/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pemerintah_pilihan_pariwisata',
        name: 'pemerintah_pilihan_pariwisata',
        component: () => import('../views/transaksi/pemerintah_pilian/pariwisata/Index.vue'),
        children: [{
          path: '/pemerintah_pilihan_pariwisata/',
          name: 'LDP_pariwisata_Dashboard',
          component: () => import('../views/transaksi/pemerintah_pilian/pariwisata/dashboard/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_pariwisata/program',
          name: 'LDP_pariwisata_Program',
          component: () => import('../views/transaksi/pemerintah_pilian/pariwisata/program/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_pariwisata/sub_kegiatan',
          name: 'LDP_pariwisata_SubKeiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/pariwisata/ListData.vue'),
        },
        {
          path: '/pemerintah_pilihan_pariwisata/tidak_wajar',
          name: 'LDP_pariwisata_TidakWajar',
          component: () => import('../views/transaksi/pemerintah_pilian/pariwisata/TidakWajar.vue'),
        }, {
          path: '/pemerintah_pilihan_pariwisata/kegiatan',
          name: 'LDP_pariwisata_Kegiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/pariwisata/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pemerintah_pilihan_pertanian',
        name: 'pemerintah_pilihan_pertanian',
        component: () => import('../views/transaksi/pemerintah_pilian/pertanian/Index.vue'),
        children: [{
          path: '/pemerintah_pilihan_pertanian/',
          name: 'pemerintah_pilihan_pertanian_Dashboard',
          component: () => import('../views/transaksi/pemerintah_pilian/pertanian/dashboard/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_pertanian/program',
          name: 'pemerintah_pilihan_pertanian_Program',
          component: () => import('../views/transaksi/pemerintah_pilian/pertanian/program/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_pertanian/sub_kegiatan',
          name: 'pemerintah_pilihan_pertanian_SubKeiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/pertanian/ListData.vue'),
        },
        {
          path: '/pemerintah_pilihan_pertanian/tidak_wajar',
          name: 'pemerintah_pilihan_pertanian_TidakWajar',
          component: () => import('../views/transaksi/pemerintah_pilian/pertanian/TidakWajar.vue'),
        }, {
          path: '/pemerintah_pilihan_pertanian/kegiatan',
          name: 'pemerintah_pilihan_pertanian_Kegiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/pertanian/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pemerintah_pilihan_kehutanan',
        name: 'pemerintah_pilihan_kehutanan',
        component: () => import('../views/transaksi/pemerintah_pilian/kehutanan/Index.vue'),
        children: [{
          path: '/pemerintah_pilihan_kehutanan/',
          name: 'LDP_kehutanan_Dashboard',
          component: () => import('../views/transaksi/pemerintah_pilian/kehutanan/dashboard/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_kehutanan/program',
          name: 'LDP_kehutanan_Program',
          component: () => import('../views/transaksi/pemerintah_pilian/kehutanan/program/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_kehutanan/sub_kegiatan',
          name: 'LDP_kehutanan_SubKeiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/kehutanan/ListData.vue'),
        },
        {
          path: '/pemerintah_pilihan_kehutanan/tidak_wajar',
          name: 'LDP_kehutanan_TidakWajar',
          component: () => import('../views/transaksi/pemerintah_pilian/kehutanan/TidakWajar.vue'),
        }, {
          path: '/pemerintah_pilihan_kehutanan/kegiatan',
          name: 'LDP_kehutanan_Kegiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/kehutanan/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pemerintah_pilihan_perdagangan',
        name: 'pemerintah_pilihan_perdagangan',
        component: () => import('../views/transaksi/pemerintah_pilian/perdagangan/Index.vue'),
        children: [{
          path: '/pemerintah_pilihan_perdagangan/',
          name: 'LDP_perdagangan_Dashboard',
          component: () => import('../views/transaksi/pemerintah_pilian/perdagangan/dashboard/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_perdagangan/program',
          name: 'LDP_perdagangan_Program',
          component: () => import('../views/transaksi/pemerintah_pilian/perdagangan/program/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_perdagangan/sub_kegiatan',
          name: 'LDP_perdagangan_SubKeiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/perdagangan/ListData.vue'),
        },
        {
          path: '/pemerintah_pilihan_perdagangan/tidak_wajar',
          name: 'LDP_perdagangan_TidakWajar',
          component: () => import('../views/transaksi/pemerintah_pilian/perdagangan/TidakWajar.vue'),
        }, {
          path: '/pemerintah_pilihan_perdagangan/kegiatan',
          name: 'LDP_perdagangan_Kegiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/perdagangan/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pemerintah_pilihan_perindustrian',
        name: 'pemerintah_pilihan_perindustrian',
        component: () => import('../views/transaksi/pemerintah_pilian/perindustrian/Index.vue'),
        children: [{
          path: '/pemerintah_pilihan_perindustrian/',
          name: 'LDP_perindustrian_Dashboard',
          component: () => import('../views/transaksi/pemerintah_pilian/perindustrian/dashboard/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_perindustrian/program',
          name: 'LDP_perindustrian_Program',
          component: () => import('../views/transaksi/pemerintah_pilian/perindustrian/program/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_perindustrian/sub_kegiatan',
          name: 'LDP_perindustrian_SubKeiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/perindustrian/ListData.vue'),
        },
        {
          path: '/pemerintah_pilihan_perindustrian/tidak_wajar',
          name: 'LDP_perindustrian_TidakWajar',
          component: () => import('../views/transaksi/pemerintah_pilian/perindustrian/TidakWajar.vue'),
        }, {
          path: '/pemerintah_pilihan_perindustrian/kegiatan',
          name: 'LDP_perindustrian_Kegiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/perindustrian/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pemerintah_pilihan_transmigrasi',
        name: 'pemerintah_pilihan_transmigrasi',
        component: () => import('../views/transaksi/pemerintah_pilian/transmigrasi/Index.vue'),
        children: [{
          path: '/pemerintah_pilihan_transmigrasi/',
          name: 'LDP_transmigrasi_Dashboard',
          component: () => import('../views/transaksi/pemerintah_pilian/transmigrasi/dashboard/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_transmigrasi/program',
          name: 'LDP_transmigrasi_Program',
          component: () => import('../views/transaksi/pemerintah_pilian/transmigrasi/program/Index.vue'),
        },
        {
          path: '/pemerintah_pilihan_transmigrasi/sub_kegiatan',
          name: 'LDP_transmigrasi_SubKeiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/transmigrasi/ListData.vue'),
        },
        {
          path: '/pemerintah_pilihan_transmigrasi/tidak_wajar',
          name: 'LDP_transmigrasi_TidakWajar',
          component: () => import('../views/transaksi/pemerintah_pilian/transmigrasi/TidakWajar.vue'),
        }, {
          path: '/pemerintah_pilihan_transmigrasi/kegiatan',
          name: 'LDP_transmigrasi_Kegiatan',
          component: () => import('../views/transaksi/pemerintah_pilian/transmigrasi/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pndukung_sekda',
        name: 'pndukung_sekda',
        component: () => import('../views/transaksi/pendukung/sekda/Index.vue'),
        children: [{
          path: '/pndukung_sekda/',
          name: 'LDP_sekda_Dashboard',
          component: () => import('../views/transaksi/pendukung/sekda/dashboard/Index.vue'),
        },
        {
          path: '/pndukung_sekda/program',
          name: 'LDP_sekda_Program',
          component: () => import('../views/transaksi/pendukung/sekda/program/Index.vue'),
        },
        {
          path: '/pndukung_sekda/sub_kegiatan',
          name: 'LDP_sekda_SubKeiatan',
          component: () => import('../views/transaksi/pendukung/sekda/ListData.vue'),
        },
        {
          path: '/pndukung_sekda/tidak_wajar',
          name: 'LDP_sekda_TidakWajar',
          component: () => import('../views/transaksi/pendukung/sekda/TidakWajar.vue'),
        }, {
          path: '/pndukung_sekda/kegiatan',
          name: 'LDP_sekda_Kegiatan',
          component: () => import('../views/transaksi/pendukung/sekda/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pndukung_dprd',
        name: 'pndukung_dprd',
        component: () => import('../views/transaksi/pendukung/dprd/Index.vue'),
        children: [{
          path: '/pndukung_dprd/',
          name: 'LDP_dprd_Dashboard',
          component: () => import('../views/transaksi/pendukung/dprd/dashboard/Index.vue'),
        },
        {
          path: '/pndukung_dprd/program',
          name: 'LDP_dprd_Program',
          component: () => import('../views/transaksi/pendukung/dprd/program/Index.vue'),
        },
        {
          path: '/pndukung_dprd/sub_kegiatan',
          name: 'LDP_dprd_SubKeiatan',
          component: () => import('../views/transaksi/pendukung/dprd/ListData.vue'),
        },
        {
          path: '/pndukung_dprd/tidak_wajar',
          name: 'LDP_dprd_TidakWajar',
          component: () => import('../views/transaksi/pendukung/dprd/TidakWajar.vue'),
        }, {
          path: '/pndukung_dprd/kegiatan',
          name: 'LDP_dprd_Kegiatan',
          component: () => import('../views/transaksi/pendukung/dprd/kegiatan/Index.vue'),
        }
      ]
      },
     
      {
        path: '/penunjang_keuangan',
        name: 'penunjang_keuangan',
        component: () => import('../views/transaksi/penunjang/keuangan/Index.vue'),
        children: [{
          path: '/penunjang_keuangan/',
          name: 'LDP_keuangan_Dashboard',
          component: () => import('../views/transaksi/penunjang/keuangan/dashboard/Index.vue'),
        },
        {
          path: '/penunjang_keuangan/program',
          name: 'LDP_keuangan_Program',
          component: () => import('../views/transaksi/penunjang/keuangan/program/Index.vue'),
        },
        {
          path: '/penunjang_keuangan/sub_kegiatan',
          name: 'LDP_keuangan_SubKeiatan',
          component: () => import('../views/transaksi/penunjang/keuangan/ListData.vue'),
        },
        {
          path: '/penunjang_keuangan/tidak_wajar',
          name: 'LDP_keuangan_TidakWajar',
          component: () => import('../views/transaksi/penunjang/keuangan/TidakWajar.vue'),
        }, {
          path: '/penunjang_keuangan/kegiatan',
          name: 'LDP_keuangan_Kegiatan',
          component: () => import('../views/transaksi/penunjang/keuangan/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/penunjang_kpp',
        name: 'penunjang_kpp',
        component: () => import('../views/transaksi/penunjang/kpp/Index.vue'),
        children: [{
          path: '/penunjang_kpp/',
          name: 'LDP_kpp_Dashboard',
          component: () => import('../views/transaksi/penunjang/kpp/dashboard/Index.vue'),
        },
        {
          path: '/penunjang_kpp/program',
          name: 'LDP_kpp_Program',
          component: () => import('../views/transaksi/penunjang/kpp/program/Index.vue'),
        },
        {
          path: '/penunjang_kpp/sub_kegiatan',
          name: 'LDP_kpp_SubKeiatan',
          component: () => import('../views/transaksi/penunjang/kpp/ListData.vue'),
        },
        {
          path: '/penunjang_kpp/tidak_wajar',
          name: 'LDP_kpp_TidakWajar',
          component: () => import('../views/transaksi/penunjang/kpp/TidakWajar.vue'),
        }, {
          path: '/penunjang_kpp/kegiatan',
          name: 'LDP_kpp_Kegiatan',
          component: () => import('../views/transaksi/penunjang/kpp/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/penunjang_litbang',
        name: 'penunjang_litbang',
        component: () => import('../views/transaksi/penunjang/litbang/Index.vue'),
        children: [{
          path: '/penunjang_litbang/',
          name: 'LDP_litbang_Dashboard',
          component: () => import('../views/transaksi/penunjang/litbang/dashboard/Index.vue'),
        },
        {
          path: '/penunjang_litbang/program',
          name: 'LDP_litbang_Program',
          component: () => import('../views/transaksi/penunjang/litbang/program/Index.vue'),
        },
        {
          path: '/penunjang_litbang/sub_kegiatan',
          name: 'LDP_litbang_SubKeiatan',
          component: () => import('../views/transaksi/penunjang/litbang/ListData.vue'),
        },
        {
          path: '/penunjang_litbang/tidak_wajar',
          name: 'LDP_litbang_TidakWajar',
          component: () => import('../views/transaksi/penunjang/litbang/TidakWajar.vue'),
        }, {
          path: '/penunjang_litbang/kegiatan',
          name: 'LDP_litbang_Kegiatan',
          component: () => import('../views/transaksi/penunjang/litbang/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/penunjang_pendidikan',
        name: 'penunjang_pendidikan',
        component: () => import('../views/transaksi/penunjang/pendidikan/Index.vue'),
        children: [{
          path: '/penunjang_pendidikan/',
          name: 'LDP_pendidikan_Dashboard',
          component: () => import('../views/transaksi/penunjang/pendidikan/dashboard/Index.vue'),
        },
        {
          path: '/penunjang_pendidikan/program',
          name: 'LDP_pendidikan_Program',
          component: () => import('../views/transaksi/penunjang/pendidikan/program/Index.vue'),
        },
        {
          path: '/penunjang_pendidikan/sub_kegiatan',
          name: 'LDP_pendidikan_SubKeiatan',
          component: () => import('../views/transaksi/penunjang/pendidikan/ListData.vue'),
        },
        {
          path: '/penunjang_pendidikan/tidak_wajar',
          name: 'LDP_pendidikan_TidakWajar',
          component: () => import('../views/transaksi/penunjang/pendidikan/TidakWajar.vue'),
        }, {
          path: '/penunjang_pendidikan/kegiatan',
          name: 'LDP_pendidikan_Kegiatan',
          component: () => import('../views/transaksi/penunjang/pendidikan/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/penunjang_penghubung',
        name: 'penunjang_penghubung',
        component: () => import('../views/transaksi/penunjang/penghubung/Index.vue'),
        children: [{
          path: '/penunjang_penghubung/',
          name: 'LDP_penghubung_Dashboard',
          component: () => import('../views/transaksi/penunjang/penghubung/dashboard/Index.vue'),
        },
        {
          path: '/penunjang_penghubung/program',
          name: 'LDP_penghubung_Program',
          component: () => import('../views/transaksi/penunjang/penghubung/program/Index.vue'),
        },
        {
          path: '/penunjang_penghubung/sub_kegiatan',
          name: 'LDP_penghubung_SubKeiatan',
          component: () => import('../views/transaksi/penunjang/penghubung/ListData.vue'),
        },
        {
          path: '/penunjang_penghubung/tidak_wajar',
          name: 'LDP_penghubung_TidakWajar',
          component: () => import('../views/transaksi/penunjang/penghubung/TidakWajar.vue'),
        }, {
          path: '/penunjang_penghubung/kegiatan',
          name: 'LDP_penghubung_Kegiatan',
          component: () => import('../views/transaksi/penunjang/penghubung/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/penunjang_perencanaan',
        name: 'penunjang_perencanaan',
        component: () => import('../views/transaksi/penunjang/perencanaan/Index.vue'),
        children: [{
          path: '/penunjang_perencanaan/',
          name: 'LDP_perencanaan_Dashboard',
          component: () => import('../views/transaksi/penunjang/perencanaan/dashboard/Index.vue'),
        },
        {
          path: '/penunjang_perencanaan/program',
          name: 'LDP_perencanaan_Program',
          component: () => import('../views/transaksi/penunjang/perencanaan/program/Index.vue'),
        },
        {
          path: '/penunjang_perencanaan/sub_kegiatan',
          name: 'LDP_perencanaan_SubKeiatan',
          component: () => import('../views/transaksi/penunjang/perencanaan/ListData.vue'),
        },
        {
          path: '/penunjang_perencanaan/tidak_wajar',
          name: 'LDP_perencanaan_TidakWajar',
          component: () => import('../views/transaksi/penunjang/perencanaan/TidakWajar.vue'),
        }, {
          path: '/penunjang_perencanaan/kegiatan',
          name: 'LDP_perencanaan_Kegiatan',
          component: () => import('../views/transaksi/penunjang/perencanaan/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/penunjang_ppd',
        name: 'penunjang_ppd',
        component: () => import('../views/transaksi/penunjang/ppd/Index.vue'),
        children: [{
          path: '/penunjang_ppd/',
          name: 'LDP_ppd_Dashboard',
          component: () => import('../views/transaksi/penunjang/ppd/dashboard/Index.vue'),
        },
        {
          path: '/penunjang_ppd/program',
          name: 'LDP_ppd_Program',
          component: () => import('../views/transaksi/penunjang/ppd/program/Index.vue'),
        },
        {
          path: '/penunjang_ppd/sub_kegiatan',
          name: 'LDP_ppd_SubKeiatan',
          component: () => import('../views/transaksi/penunjang/ppd/ListData.vue'),
        },
        {
          path: '/penunjang_ppd/tidak_wajar',
          name: 'LDP_ppd_TidakWajar',
          component: () => import('../views/transaksi/penunjang/ppd/TidakWajar.vue'),
        }, {
          path: '/penunjang_ppd/kegiatan',
          name: 'LDP_ppd_Kegiatan',
          component: () => import('../views/transaksi/penunjang/ppd/kegiatan/Index.vue'),
        }
      ]
      },
      {
        path: '/pengawas_inspektorat',
        name: 'pengawas_inspektorat',
        component: () => import('../views/transaksi/pengawas/inspektorat/Index.vue'),
        children: [{
          path: '/pengawas_inspektorat/',
          name: 'LDP_inspektorat_Dashboard',
          component: () => import('../views/transaksi/pengawas/inspektorat/dashboard/Index.vue'),
        },
        {
          path: '/pengawas_inspektorat/program',
          name: 'LDP_inspektorat_Program',
          component: () => import('../views/transaksi/pengawas/inspektorat/program/Index.vue'),
        },
        {
          path: '/pengawas_inspektorat/sub_kegiatan',
          name: 'LDP_inspektorat_SubKeiatan',
          component: () => import('../views/transaksi/pengawas/inspektorat/ListData.vue'),
        },
        {
          path: '/pengawas_inspektorat/tidak_wajar',
          name: 'LDP_inspektorat_TidakWajar',
          component: () => import('../views/transaksi/pengawas/inspektorat/TidakWajar.vue'),
        }, {
          path: '/pengawas_inspektorat/kegiatan',
          name: 'LDP_inspektorat_Kegiatan',
          component: () => import('../views/transaksi/pengawas/inspektorat/kegiatan/Index.vue'),
        }
      ]
      },
      
      {
        path: '/kewilayahan_kecamatan',
        name: 'kewilayaha_kecamatan',
        component: () => import('../views/transaksi/kewilayahan/kecamatan/Index.vue'),
        children: [{
          path: '/kewilayahan_kecamatan/',
          name: 'LDP_kecamatan_Dashboard',
          component: () => import('../views/transaksi/kewilayahan/kecamatan/dashboard/Index.vue'),
        },
        {
          path: '/kewilayahan_kecamatan/program',
          name: 'LDP_kecamatan_Program',
          component: () => import('../views/transaksi/kewilayahan/kecamatan/program/Index.vue'),
        },
        {
          path: '/kewilayahan_kecamatan/sub_kegiatan',
          name: 'LDP_kecamatan_SubKeiatan',
          component: () => import('../views/transaksi/kewilayahan/kecamatan/ListData.vue'),
        },
        {
          path: '/kewilayahan_kecamatan/tidak_wajar',
          name: 'LDP_kecamatan_TidakWajar',
          component: () => import('../views/transaksi/kewilayahan/kecamatan/TidakWajar.vue'),
        }, {
          path: '/kewilayahan_kecamatan/kegiatan',
          name: 'LDP_kecamatan_Kegiatan',
          component: () => import('../views/transaksi/kewilayahan/kecamatan/kegiatan/Index.vue'),
        }
      ]
      },
      
      {
        path: '/pemerintah_umum_dkbp',
        name: 'kewilayaha_dkbp',
        component: () => import('../views/transaksi/pemerintah_umum/dkbp/Index.vue'),
        children: [{
          path: '/pemerintah_umum_dkbp/',
          name: 'LDP_dkbp_Dashboard',
          component: () => import('../views/transaksi/pemerintah_umum/dkbp/dashboard/Index.vue'),
        },
        {
          path: '/pemerintah_umum_dkbp/program',
          name: 'LDP_dkbp_Program',
          component: () => import('../views/transaksi/pemerintah_umum/dkbp/program/Index.vue'),
        },
        {
          path: '/pemerintah_umum_dkbp/sub_kegiatan',
          name: 'LDP_dkbp_SubKeiatan',
          component: () => import('../views/transaksi/pemerintah_umum/dkbp/ListData.vue'),
        },
        {
          path: '/pemerintah_umum_dkbp/tidak_wajar',
          name: 'LDP_dkbp_TidakWajar',
          component: () => import('../views/transaksi/pemerintah_umum/dkbp/TidakWajar.vue'),
        }, {
          path: '/pemerintah_umum_dkbp/kegiatan',
          name: 'LDP_dkbp_Kegiatan',
          component: () => import('../views/transaksi/pemerintah_umum/dkbp/kegiatan/Index.vue'),
        }
      ]
      },
       
    ],
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/lostToken',
    name: 'lostToken',
    component: lostToken
  }, {
    path: '/cut_acces',
    name: 'CutAcces',
    component: CutAcces
  },
  {
    path: '/noakses',
    name: 'NoAkses',
    component: AcessDenied
  },

  {
    path: '/:pathMatch(.*)*',
    component: PathNotFound
  },

]

const router = new VueRouter({
  routes
})

export default router
