<template>
    <div class="app m-2">
        <apexcharts class="" width="700" height="380" type="bar" :options="chartOptions" :series="series"></apexcharts>
    </div>
</template>

<script>
    import VueApexCharts from "vue-apexcharts";
    import axios from '../../config/Axios'
    import Base from '../../mixins/Base'

    export default {
        name: "Chart",
        components: {
            apexcharts: VueApexCharts
        },
        mixins :[Base],
        data: function () {
            return {
                categories: [],
                series: [{
                    name: 'Sub Kegiatan Wajar',
                    data: [],
                }, {
                    name: 'Sub Kegiatan Tidak Wajar',
                    data: [],
                }, ],
                chartOptions: {},
            };
        },
        mounted() {
            this.load_bar();
        },
        methods: {
            async load_bar() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: 'api/dashboard/bar',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) { 
                        self.series = response.data.result.series; 
                        self.chartOptions = {
                            
                            colors: ['#62d975', '#fc671e'],
                            chart: {
                                type: 'bar',
                                height: 300, 
                            },
                            plotOptions: {

                                bar: {
                                    horizontal: false,
                                    columnWidth: '50%',
                                    endingShape: 'rounded'
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                labels: {
                                    show: false,
                                },
                                categories: response.data.result.categories
                            },
                            yaxis: {
                                title: {
                                    text: 'Jumlah Sub Kegiatan'
                                }
                            },
                            fill: {
                                opacity: 1
                            },

                            tooltip: {
                                onDatasetHover: {
                                    highlightDataSeries: false,
                                },
                                fixed: {
                                    enabled: false,
                                    position: 'topRight',
                                    offsetX: 0,
                                    offsetY: 0,
                                },
                                y: {
                                    formatter: function (val) {
                                        return "" + val + " Sub Kegiatan"
                                    }
                                }
                            }

                        }
                        self.chartOptions.xaxis.categories = response.data.result.categories;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }


    };
</script>