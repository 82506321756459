<template>
    <div> 
        <div class="">
            <div class=" ">
                <div class="table-responsive">
                    <table class="table mb-0 table-borderless" width="100%">
                        <thead>
                            <tr>
                                <th width="30%">Nama Rumpun </th>
                                <th width="15%">Belanja Total </th>
                                <th width="15%">Belanja Wajar</th>
                                <th width="30%"> Belanja Tidak Wajar </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="rs in rs_rumpun" v-bind:key="rs.kode_rumpun">
                                <td><b>{{rs.kode_rumpun}}<br>{{rs.nama_rumpun}}</b></td>
                                <td> <b class="text-primary">{{rs.jm_rumpun}}</b> Sub Kegiatan <br><b> Rp.
                                        {{formatPrice(parseInt( rs.total_belanja))}},-</b></td>
                                <td> <b class="text-info">{{rs.jm_rumpun_wajar}}</b> Wajar <br> <b>
                                        Rp.{{formatPrice(parseInt( rs.total_belanja_wajar))}},-</b> </td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-6"> <b
                                                class="text-danger mr-3">{{rs.jm_rumpun_tidak_wajar}}</b> Tidak Wajar
                                        </div>
                                        <div class="col-md-6 float-right"><b class="float-right">
                                                {{formatPrice(parseInt( rs.total_belanja_tidak_wajar))}},-</b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"> Batas Atas Menurut ASB </div>
                                        <div class="col-md-6 float-right"><b class="float-right">
                                                {{formatPrice(parseInt( rs.max_asb_wajar))}},-</b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"> Belanja Tidak Wajar Menurut ASB </div>
                                        <div class="col-md-6 float-right"
                                            v-if="parseInt( rs.total_belanja_tidak_wajar) > parseInt( rs.max_asb_wajar)">
                                            <b class="float-right text-danger">
                                                {{formatPrice(parseInt( rs.total_belanja_tidak_wajar)- parseInt( rs.max_asb_wajar))}},-</b>
                                        </div>
                                        <div class="col-md-6 float-right" v-else><b class="float-right"> 0,-</b></div>
                                    </div>



                                </td>


                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            rs_rumpun: []
        },
        comments: {
            rumpunData() {
                return this.rs_rumpun;
            }
        },
        methods:{
            formatPrice(value_2) {
        let val = (value_2 / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
        }


    }
</script>

<style>

</style>