export default {
    mamespaced : true,
    state: { 
        Program: [],  
        Kegiatan :[],
        SubKegiatan :[],
        Rumpun :[],
        RumpunKomponen :{},
        Bidang:{},
        IdKegiatan : {},
        IdSubKegiatan : {},
        IdRumpun : {},
        RsData : [],
        
    },
    mutations: { 
        SetRsData(state, value){
            state.RsData = value 
        },
        SetBidang(state, value) {
            state.Bidang = value 
        },
        SetProgram(state, value) {
            state.Program = value 
        },
        SetKegiatan(state, value) {
            state.Kegiatan = value 
        },
       
        SetSubKegiatan(state, value) { 
            state.SubKegiatan = value 
        },
        SetRumpun(state, value){
            state.Rumpun = value 
        },
        SetRumpunKomponen(state, value){
            state.RumpunKomponen = value 
        },
        SetIdKegiatan(state, value){
            state.IdKegiatan = value 
        },
        SetIdSubKegiatan(state, value){
            state.IdSubKegiatan = value 
        },
        SetIdRumpun(state, value){
            state.IdRumpun = value 
        },
         
    },
    actions: {
   }, 
}
