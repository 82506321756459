export default {
    mamespaced : true,
    state: { 
        information: [], 
    },
    mutations: { 
        setInformation(state, value) {
            state.information = value 
        }
    },
    actions: {
   },
   
}
