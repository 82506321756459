<template>
    <div class="bg-primary">
        <vs-dialog v-model="active" width='800px' prevent-close not-close>
            <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title m-1">
                    <h4 class="card-title"> <i :class="'fa ' +  nameLevel.icon"></i>
                        <span class=" ml-2 mr-3">{{nameLevel.level_name}}</span>
                    </h4>
                </div>
                <div class="iq-card-header-toolbar d-flex align-items-center">
                    <div class="dropdown">
                        <button class="btn iq-bg-danger btn-sm float-left" @click="close()">
                            <i class="fa fa-close ml-1"></i> Close </button>
                    </div>
                </div>
            </div>

            <div class="iq-card-body">
                <div>
                    <div v-for="rs_menu in rs_menu" v-bind:key="rs_menu.id_menu">
                        <div class="row">
                            <div class="col-sm-4">
                                <i :class="rs_menu.icon +' mr-3'"></i><b>{{rs_menu.nm_menu}}</b> 
                            </div> 
                            <div class="col-sm-2">
                                <!-- <level-akses-buton 
                               :info ="'c'"
                                :act = 'rs_menu.akses_c'  
                                :value = 'rs_menu' 
                                :nameLevel = 'nameLevel.id_level' 
                                :akses = 'rs_menu.akses_r'
                                @menu_akses = 'aksesMenu'>
                                Read {{rs_menu.akses_c}}
                                </level-akses-buton> -->
                                <div class="badge badge-pill badge-primary" v-if="rs_menu.akses_r =='1'"
                                    @click="menu_akses(nameLevel.id_level,rs_menu.menu_key, 'menu' ,rs_menu.akses_c, '0' ,rs_menu.akses_u ,rs_menu.akses_d)">
                                    <i class="fa fa-check mr-2"></i>Read </div>

                                <div class="badge badge-pill badge-warning" v-else
                                    @click="menu_akses(nameLevel.id_level,rs_menu.menu_key, 'menu' ,rs_menu.akses_c, '1' ,rs_menu.akses_u ,rs_menu.akses_d)">
                                    <i class="fa fa-warning mr-2"></i>Read
                                </div>

                            </div>
                            <div class="col-sm-2">
                                <div class="badge badge-pill badge-primary" v-if="rs_menu.akses_c == '1'"
                                    @click="menu_akses(nameLevel.id_level,rs_menu.menu_key, 'menu' ,'0', rs_menu.akses_r ,rs_menu.akses_u ,rs_menu.akses_d)">
                                    <i class="fa fa-check mr-2"></i>Create</div>
                                <div class="badge badge-pill badge-warning" v-else
                                    @click="menu_akses(nameLevel.id_level,rs_menu.menu_key, 'menu' ,'1', rs_menu.akses_r ,rs_menu.akses_u ,rs_menu.akses_d)">
                                    <i class="fa fa-warning mr-2"></i>Create</div>
                            </div>
                            <div class="col-sm-2">
                                <div class="badge badge-pill badge-primary" v-if="rs_menu.akses_u =='1'"
                                    @click="menu_akses(nameLevel.id_level,rs_menu.menu_key, 'menu' ,rs_menu.akses_c, rs_menu.akses_r ,'0' ,rs_menu.akses_d)">
                                    <i class="fa fa-check mr-2"></i>Update </div>

                                <div class="badge badge-pill badge-warning" v-else
                                    @click="menu_akses(nameLevel.id_level,rs_menu.menu_key, 'menu' ,rs_menu.akses_c, rs_menu.akses_r ,'1' ,rs_menu.akses_d)">
                                    <i class="fa fa-warning mr-2"></i>Update
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="badge badge-pill badge-primary" v-if="rs_menu.akses_d =='1'"
                                    @click="menu_akses(nameLevel.id_level,rs_menu.menu_key, 'menu' ,rs_menu.akses_c, rs_menu.akses_r ,rs_menu.akses_u ,'0')">
                                    <i class="fa fa-check mr-2"></i>Delete </div>

                                <div class="badge badge-pill badge-warning" v-else
                                    @click="menu_akses(nameLevel.id_level,rs_menu.menu_key, 'menu' ,rs_menu.akses_c, rs_menu.akses_r ,rs_menu.akses_u ,'1')">
                                    <i class="fa fa-warning mr-2"></i>Delete
                                </div>
                            </div>
                        </div>
                        <div v-for="rs_submenu in rs_menu.menu_sub" v-bind:key="rs_submenu.id_menu_sub">

                            <div class="row">
                                <div class="col-sm-4">
                                    <b class=" ml-4"> - {{rs_submenu.nm_menu}}</b>
                                </div>
                                <div class="col-sm-2">
                                    <div class="badge badge-pill badge-info" v-if="rs_submenu.akses_r =='1'"
                                        @click="menu_akses(nameLevel.id_level,rs_submenu.menu_key, 'sub_menu' ,rs_submenu.akses_c, '0' ,rs_submenu.akses_u ,rs_submenu.akses_d)">

                                        <i class="fa fa-check mr-2"></i>Read </div>
                                    <div class="badge badge-pill badge-danger" v-else
                                        @click="menu_akses(nameLevel.id_level,rs_submenu.menu_key, 'sub_menu' ,rs_submenu.akses_c, '1' ,rs_submenu.akses_u ,rs_submenu.akses_d)">

                                        <i class="fa fa-warning mr-2"></i>Read </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="badge badge-pill badge-info" v-if="rs_submenu.akses_c =='1'"
                                        @click="menu_akses(nameLevel.id_level,rs_submenu.menu_key, 'sub_menu' ,'0', rs_submenu.akses_r ,rs_submenu.akses_u ,rs_submenu.akses_d)">
                                        <i class="fa fa-check mr-2"></i>Create
                                    </div>
                                    <div class="badge badge-pill badge-danger" v-else
                                        @click="menu_akses(nameLevel.id_level,rs_submenu.menu_key, 'sub_menu' ,'1', rs_submenu.akses_r ,rs_submenu.akses_u ,rs_submenu.akses_d)">
                                        <i class="fa fa-warning mr-2"></i>Create
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="badge badge-pill badge-info" v-if="rs_submenu.akses_u =='1'"
                                        @click="menu_akses(nameLevel.id_level,rs_submenu.menu_key, 'sub_menu' ,rs_submenu.akses_c, rs_submenu.akses_r ,'0' ,rs_submenu.akses_d)">
                                        <i class="fa fa-check mr-2"></i>Update
                                    </div>
                                    <div class="badge badge-pill badge-danger" v-else
                                        @click="menu_akses(nameLevel.id_level,rs_submenu.menu_key, 'sub_menu' ,rs_submenu.akses_c, rs_submenu.akses_r ,'1' ,rs_submenu.akses_d)">
                                        <i class="fa fa-warning mr-2"></i>Update
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <div class="badge badge-pill badge-info" v-if="rs_submenu.akses_d === '1'"
                                        @click="menu_akses(nameLevel.id_level,rs_submenu.menu_key, 'sub_menu' ,rs_submenu.akses_c, rs_submenu.akses_r ,rs_submenu.akses_u ,'0')">
                                        <i class="fa fa-check mr-2"></i>Delete
                                    </div>
                                    <div class="badge badge-pill badge-danger" v-else
                                        @click="menu_akses(nameLevel.id_level,rs_submenu.menu_key, 'sub_menu' ,rs_submenu.akses_c, rs_submenu.akses_r ,rs_submenu.akses_u ,'1')">
                                        <i class="fa fa-warning mr-2"></i>Delete
                                    </div>'
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
    import axios from '../../../config/Axios';
    import Base from '../../../mixins/Base';
    import {
        mapMutations,
        mapState
    } from 'vuex'
// import levelAksesButon from './levelAksesButon.vue';

    export default {
  components: {   },
        emits: ["closeBtn"],
        mixins: [Base],
        data() {
            return {}
        },
        props: {
            levelName: {},
            active_user: {},
        },
        computed: {
            ...mapState({
                rs_menu: state => state.AclLevel.rs_menu
            }),
            active() {
                return this.active_user.levelAkses;
            },
            nameLevel() {
                return this.levelName;
            },
        },
        mounted() {
            this.Loading();
        },
        methods: {
            ...mapMutations({
                SetRsMenu: 'SetRsMenu'
            }),
            close() {
                this.$emit("closeBtn", '');
            },
            async get_menu() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: 'api/acl/level_akses/menu',
                        data: {
                            id_level: self.nameLevel.id_level
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.SetRsMenu(response.data.result);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            // aksesMenu(id_level, menu_key, st, c, r, u, d){
                
            //     console.log(id_level ,menu_key, st, c, r, u, d)
            // },
            async menu_akses(d_level, menu_key, st, c, r, u, d) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: 'api/acl/level_akses/menu_akses',
                        data: {
                            level_key: d_level,
                            menu_key: menu_key,
                            st: st,
                            akses_c: c,
                            akses_r: r,
                            akses_u: u,
                            akses_d: d,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.SetRsMenu(response.data.result);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }
    }
</script>

<style>
    .my-custom-scrollbar {
        position: relative;
        height: 300px;
        overflow: auto;
    }

    .wrapper-scroll-x {
        display: block;
    }
</style>