<template> 
<div class="center">
  <vs-dialog not-close v-model="dataUser" width='500px' prevent-close>

    <div class=" ">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title">
          <h4 class="card-title">Edit </h4>
        </div>
      </div>
      <div class="iq-card-body">
        
          <div class="form-group">
            <label for="email">Username </label>
            <input type="text" class="form-control" v-model="edit.username" disabled="">
          </div>
          <div class="form-group">
            <label for="email">Nama </label>
            <input type="text" class="form-control" v-model="edit.nm_lengkap">
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Level</label>
            <select class="form-control" id="exampleFormControlSelect1" v-model="edit.level_key"
              :placeholder="edit.level_name">
              <option v-for="get_level in get_level" v-bind:key="get_level.level_key" v-bind:value="get_level.level_key"
                :selected="get_level.level_key == edit.level_key ">{{get_level.level_name}}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="email">No Hp </label>
            <input type="text" class="form-control" v-model="edit.no_hp">
          </div>
          <div class="form-group">
            <label for="email">Email address:</label>
            <input type="email" class="form-control" id="email1" v-model="edit.email">
          </div>

          <button  class="btn btn-primary btn-sm mr-1" @click="simpan(edit)"><i
              class="fa fa-save"></i>Submit</button>
          <button   class="btn iq-bg-danger btn-sm ml-1" @click="close()"> <i
              class="fa fa-close"></i>cancle</button>
        
      </div>
    </div>
  </vs-dialog>
</div>
</template>

<script>
  // import axios from '../../../config/Axios'
  import Base from '../../../mixins/Base'
  export default {
    mixins: [Base],
    data() {
      return {

      }
    },
    emits: ["CloseButton", "UpdateUser"],
    props: {
      active_edit: {},
      user_key: {},
      rs_edit: {},
      level: []
    },
    computed: {
      dataUser() {
        return this.active_edit;
      },
      edit() {
        return this.rs_edit;
      },
      get_level() {
        return this.level;
      }
    },
    mounted() {

    },
    methods: {
      simpan(edit) {
        this.$emit("UpdateUser", edit);
        this.$emit("CloseButton", false);
      },
      close() {
        this.$emit("CloseButton", false);
      },
    }

  }
</script>

<style>

</style>