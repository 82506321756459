<template>
    <div class="bg-primary">
        <vs-dialog v-model="active" width='800px' prevent-close not-close>
            <div class="iq-card-body">

                <div class="form-group">
                    <label for="email">Level Name </label>
                    <input type="text" class="form-control" v-model="rsLevel.level_name" v-validate="'required'"
                        name="level_name">
                    <span class="text-danger">{{ errors.first('level_name') }}</span>
                </div>
                <div class="form-group">
                    <label for="email">Icon</label>
                    <input type="text" class="form-control" v-model="rsLevel.icon" v-validate="'required'" name="icon">
                    <span class="text-danger">{{ errors.first('icon') }}</span>
                </div>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Induk</label>
                    <select class="form-control" id="exampleFormControlSelect1" name="induk" v-model="rsLevel.induk"
                        v-validate="'required'" :placeholder="rsLevel.induk">
                        <option v-for="induk in induk" v-bind:key="induk.id" v-bind:value="induk.induk"
                            :selected="induk.induk == rsLevel.induk ">{{induk.induk}}
                        </option>
                    </select>
                    <span class="text-danger">{{ errors.first('induk') }}</span>
                </div>
                <div class="form-group">
                    <label for="email">Keterangan</label>
                    <input type="text" class="form-control" v-model="rsLevel.ket" v-validate="'required'" name="ket">
                    <span class="text-danger">{{ errors.first('ket') }}</span>
                </div>

                <div class="form-group">
                    <label for="email">Status</label>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio1" name="customRadio" value="1" v-model="rsLevel.st"
                                    class="custom-control-input">
                                <label class="custom-control-label" for="customRadio1"> Enable</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio2" name="customRadio" value="0" v-model="rsLevel.st"
                                    class="custom-control-input">
                                <label class="custom-control-label" for="customRadio2"> Disable</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-primary btn-sm mr-1" @click="simpan(rsLevel)"><i
                        class="fa fa-save"></i>Update</button>

                <button class="btn iq-bg-danger btn-sm ml-1" @click="close()"> <i
                        class="fa fa-close"></i>cancle</button>

            </div>
        </vs-dialog>
    </div>
</template>

<script>
    import axios from '../../../config/Axios';
    import Base from '../../../mixins/Base';
    import {
        mapMutations,
        mapState
    } from 'vuex'

    export default {
        emits: ["closeBtn"],
        mixins: [Base],
        data() {
            return {
                induk: [],
            }
        },
        props: { 
            active_user: {},
            addLevel: {}
        },
        computed: {
            ...mapState({
                rs_level: state => state.AclLevel.rs_level,
            }),
            active() {
                return this.active_user.editLevel;
            },
            nameLevel() {
                return this.levelName;
            },
            rsLevel() {
                return this.addLevel
            }

        },
        mounted() {
            this.Loading();
            this.get_induk();
        },
        methods: {
            ...mapMutations({
                SetLevel: 'SetLevel',
            }),
            close() {
                this.$emit("closeBtn", '');
            },
            simpan() {
                const self = this;
                this.$validator.validateAll().then(result => {
                    if (result) {
                        axios({
                                method: 'POST',
                                url: 'api/acl/level/update',
                                data: this.rsLevel,
                                headers: {
                                    'Authorization': self.isAuthenticated
                                }
                            })
                            .then(function (response) {
                                self.SetLevel(response.data.result)
                                self.notification('', response.data.status, response.data.message);
                                self.$emit("closeBtn", {
                                    user: false,
                                    editLevel: false,
                                    addLevel: false,
                                    levelAkses: false
                                });
                            }).catch(err => {
                                self.pesan = err.message;
                                self.notification('warning', "Error", err.message);
                            });
                    }
                })

            },

            async get_induk() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: 'api/acl/level/induk',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.induk = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });

            }
        }
    }
</script>

<style>
    .my-custom-scrollbar {
        position: relative;
        height: 300px;
        overflow: auto;
    }

    .wrapper-scroll-x {
        display: block;
    }
</style>