import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import 'vue-select/dist/vue-select.css';
import VueApexCharts from 'vue-apexcharts'
import VeeValidate from 'vee-validate';
import vSelect from 'vue-select'; 

import "./assets/css/bootstrap.min.css"
import "./assets/css/typography.css"
import "./assets/css/style.css"
import "./assets/css/responsive.css" 
import "./assets/css/swiper-bundle.min.css"

Vue.use(VeeValidate);
Vue.use(VueApexCharts)
Vue.use(Vuesax);
Vue.component('v-select', vSelect);
Vue.component('apexchart', VueApexCharts)




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
