<template>
    <div class=" bg-primary">
        <vs-dialog v-model="active" width='800px' prevent-close not-close>
            <div class="iq-card-body">
                <div class="row mb-2">
                    <div class="col-lg-1 col-sm-3 col-xs-3 mt-3">
                        <div class="icon iq-icon-box rounded dark-icon-light iq-bg-primary mr-3 ">
                            <i :class="'fa ' +  levelName.icon"></i>
                        </div>
                    </div>
                    <div class="col-lg-10 col-sm-6 col-xs-5">
                        <div class="d-flex align-items-center justify-content-between  pl-3">
                            <span class="title text-dark h4">{{levelName.level_name}}</span>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 ml-1">
                                <div class="center con-selects">
                                    <vs-select filter placeholder="Daftar user" v-model="user_id" class="col-md-12"
                                        @input="AddUser()">
                                        <vs-option v-for="userList in userList " v-bind:key="userList.user_id"
                                            :label="userList.username" :value="userList.user_id">
                                            {{userList.username}}
                                        </vs-option>

                                    </vs-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-sm-1 col-xs-1">
                        <button class="btn iq-bg-danger btn-sm " @click="close()"> <i
                                class="fa fa-close ml-1"></i></button>
                    </div>
                </div>


                <hr>


                <div class="row">
                    <div class="col-lg-12" v-for="rs_user in rs_user" v-bind:key="rs_user.user_key">
                        <div
                            class="iq-card iq-card-block iq-card-stretch mb-2 text-white  bg-primary wrapper-scroll-x ">
                            <div class="iq-card-body p-1 ">
                                <div class="">
                                    <div class="row">
                                        <div class="iq-user  col-md-2 ml-3">
                                            <img :src="rs_user.foto" alt="" class="avatar-70 rounded-circle">
                                        </div>
                                        <div class="ml-3 col-sm-6">

                                            <h6 class="text-white">{{rs_user.username}}</h6>
                                            <p class="mb-0 font-size-12">{{rs_user.nm_lengkap}}</p>
                                            <p class="mb-0 font-size-12"><b>{{rs_user.email}}</b></p>
                                            <p class="mb-0 font-size-12"><b> {{rs_user.level_name}}</b></p>
                                            <!-- <p class="mb-0 font-size-12">Last Login 24 jan, 2020</p> -->
                                        </div>
                                        <div class="col-sm-2">
                                            <h6 class="text-dark"></h6>
                                            <div class="mt-3">
                                                <button class="btn  text-white btn-sm"
                                                    @click="deleteUser(rs_user.user_key)"><i
                                                        class="fa fa-trash ml-2"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        </vs-dialog>
    </div>
</template>

<script>
    import axios from '../../../config/Axios';
    import Base from '../../../mixins/Base';
    import {
        mapMutations,
        mapState
    } from 'vuex'

    export default {
        emits: ["closeBtn", "updateLevel"],
        mixins: [Base],
        data() {
            return {
                user_id: '',
                userList: []
            }
        },
        props: {
            active_user: {}, 
            levelName: {}
        },
        computed: {
            ...mapState({
                rs_level: state => state.AclLevel.rs_level,
                rs_user: state => state.AclLevel.rs_user,
            }),
            active() {
                return this.active_user.user;
            },
             
            nameLevel() {
                return this.levelName;
            }

        },
        mounted() {
            this.Loading();
            this.GetUser()
        },
        methods: {
            ...mapMutations({
                SetLevel: 'SetLevel',
                SetRsUser : 'SetRsUser'
            }),

            close() {
                this.$emit("closeBtn", '');
                this.GetLevel();
            },
            async GetLevel(search) {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: 'api/acl/level/list',
                        data: {
                            cari: search
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.SetLevel(response.data.result);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            }, 
            async AddUser() {
                const self = this;
                await axios({
                        method: 'POST',
                        url: 'api/acl/level_route/act_insert',
                        data: {
                            user_id: self.user_id,
                            id_level: self.levelName.id_level
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.Loading();
                        self.SetRsUser(response.data.result);
                        self.notification('', response.data.status, response.data.message);
                        self.GetUser(); 
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });

            },
            async deleteUser(user_key) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: 'api/acl/level_route/delete',
                        data: {
                            user_id: user_key,
                            id_level: self.levelName.id_level
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.Loading();
                        self.SetRsUser(response.data.result);//self.user_list = response.data.result;
                        self.notification('', response.data.status, response.data.message);
                        self.GetUser();
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });

            },
            async GetUser() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: 'api/acl/level_route/user',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.userList = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            }
        }
    }
</script>

<style>
    .my-custom-scrollbar {
        position: relative;
        height: 300px;
        overflow: auto;
    }

    .wrapper-scroll-x {
        display: block;
    }
</style>