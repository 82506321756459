export default {
    mamespaced : true,
    state: {
        AppName : {},
        getBreadcrumb: [], 
    },
    mutations: { 
        SetBreadcrumb(state, value) {
            state.getBreadcrumb = value 
        },
        setAppName(state, value){
            state.AppName  = value
        }
    },
    actions: {
   },
   
}
