<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>

</style>
<script>
  export default ({
    components: {

    },
    data() {
      return {}
    },
    mounted() {

    },
    methods: {}
  })
</script>