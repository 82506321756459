import axios from '../config/Axios'
// import {
//     mapMutations,
//   } from 'vuex'

const Base = {
    data() {
        return {
            isAuthenticated: localStorage.getItem('token_auth'),
            akses: {},
            pesan: ""
            // hash :  window.location.hash, 
        }
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        // ...mapMutations({
        //     SetBreadcrumb: 'SetBreadcrumb', 
        //     setAppName : 'setAppName',
        //     setInformation : 'setInformation',
        //     setMessages : 'setMessages'
        //         }),

        //api/aut/cek
        async cek_akses() {
            const self = this;
            await axios({
                    method: 'PUT',
                    url: 'api/aut/cek',
                    data: {
                        url: window.location.hash
                    },
                    headers: {
                        'Authorization': self.isAuthenticated
                    }
                })
                .then(function (response) {
                    const st = response.data.result.st;
                    if (st == '0') {
                        return self.$router.push({
                            name: 'NoAkses'
                        });
                    }
                    if (st == '00') {
                        return self.$router.push({
                            name: 'CutAcces'
                        });
                    }
                    if (st == '1') {
                        self.akses = response.data.result.rs
                        if (response.data.result.rs.aksesr == '0') {
                            return self.$router.push({
                                name: 'NoAkses'
                            });
                        }
                    }
                }).catch(err => {
                    self.pesan = err.message;
                    return self.$router.push({
                        name: 'Login'
                    });
                });

        },
        async cek_akses_cs(url) {
            const self = this;
            await axios({
                    method: 'PUT',
                    url: 'api/aut/cek',
                    data: {
                        url: url
                    },
                    headers: {
                        'Authorization': self.isAuthenticated
                    }
                })
                .then(function (response) {
                    const st = response.data.result.st;
                    if (st == '0') {
                        return self.$router.push({
                            name: 'NoAkses'
                        });
                    }
                    if (st == '00') {
                        return self.$router.push({
                            name: 'CutAcces'
                        });
                    }
                    if (st == '1') {
                        self.akses = response.data.result.rs
                        if (response.data.result.rs.aksesr == '0') {
                            return self.$router.push({
                                name: 'NoAkses'
                            });
                        }
                    }
                }).catch(err => {
                    self.pesan = err.message;
                    return self.$router.push({
                        name: 'Login'
                    });
                });

        },
        async cek_token() {
            const self = this;
            await axios({
                    method: 'PATCH',
                    url: 'api/aut/cek_token',
                    data: self.loginData,
                    headers: {
                        'Authorization': self.isAuthenticated
                    }
                })
                .then(function (response) {
                    const st = response.data.result.st;
                    if (st == '0') {
                        return self.$router.push({
                            name: 'lostToken'
                        });

                    }
                    if (st == '00') {
                        return self.$router.push({
                            name: 'CutAcces'
                        });

                    }
                }).catch(err => {
                    self.pesan = err.message;
                    return self.$router.push({
                        name: 'lostToken'
                    });
                });

        },

        notification(color, title, text) {
            this.$vs.notification({
                color: color,
                duration: '12000',
                position: 'bottom-right',
                title: '<b> ' + title + ' </b>',
                text: text
            })

        },
        openLoading() {
            const loading = this.$vs.loading({
                type: 'points'
            })
            setTimeout(() => {
                loading.close()
            }, 1000)
        },
        get_url() {
            const url = window.location.toString();
            this.urls = url.split("#", 2)[1];
        },
        Loading() {
            const loading = this.$vs.loading({
                type: 'points'
            })
            setTimeout(() => {
                loading.close()
            }, 200)
        },

        Loadings( ) { 
            const loading = this.$vs.loading({
                type: 'points'
            })
            setTimeout(() => {
                loading.close()
            }, 200)
            
        },
    },

    cekNotifikasi(){ }


    //   seter_Breadcrumb(Breadcrumb , AppName ){ 
    //     this.setAppName(AppName);
    //     this.SetBreadcrumb(Breadcrumb) 
    //   },

}


export default Base;